import environment from '@/environment';
import { OMTContext } from '@/hooks/onlineMembershipTemplates/omtContext';
import { ReadyStateContext } from '@/hooks/onlineMembershipTemplates/useReadyState';
import { useDSB } from '@/hooks/useDSB';
import useFeedback from '@/hooks/useFeedback';
import useSdk from '@/hooks/useSdk';
import useUser from '@/hooks/useUser';
import { XMarkIcon } from '@heroicons/react/24/solid';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router';
import Button from '../Button';
import Input from '../Input';
import Modal from '../Modal';
import Smart from '../SmartForm';
import useBackends from '@/hooks/useBackends';

export const NextButton = ({ readyState }) => {
  const { canNext } = useContext(ReadyStateContext);
  const {
    membershipTemplate,
    selectedVersion,
    changeStatus,
    mutate,
    mutateVersion,
    statusChangePending,
    completeState,
    allowedTransitions,
    isDSBUser,
  } = useContext(OMTContext);
  const [startSetLive, setStartSetLive] = useState(false);
  const { backends } = useBackends();

  const [liveComment, setLiveComment] = useState('');
  const { withFeedback: liveFeedback, pending: livePending } = useFeedback();
  const { api } = useSdk();
  const { post } = useDSB();
  const { withFeedback, pending } = useFeedback();
  const user = useUser();
  const [customerRelease, setCustomerRelease] = useState(null);
  const mailItems = useMemo(() => {
    if (!selectedVersion) return [];
    const mails = new Set();
    if (selectedVersion.comments) {
      for (const comment of selectedVersion.comments) {
        if (comment?.metadata?.email) mails.add(comment.metadata.email);
      }
    }
    return Array.from(mails).map((email) => ({ label: email, value: email }));
  }, [selectedVersion]);

  const formsValid = useMemo(() => {
    return Object.values(completeState).every((v) => v);
  }, [completeState]);

  const manualLive = useCallback(async () => {
    liveFeedback(
      async () => {
        if (allowedTransitions.includes('Live')) {
          await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
            transition: 'Live',
            payload: isDSBUser ? `{system} ${liveComment}` : '{system} Abo wurde vom Kunde live gestellt',
          });
          await mutate();
          setStartSetLive(false);
        } else {
          alert('Transition nicht erlaubt');
        }
      },
      { success: 'Kommentar gespeichert', error: 'Kommentar konnte nicht gespeichert werden' },
    );
  }, [liveFeedback, api, liveComment, selectedVersion]);

  switch (selectedVersion?.draftStatus) {
    case 'Entwurf':
      return (
        <Button $primary loading={statusChangePending} onClick={() => changeStatus('In Vorbereitung')}>
          Abo-Anlage freigeben
        </Button>
      );
    case 'In Vorbereitung':
      return (
        <>
          <Button
            $primary
            $disabled={!formsValid || !canNext}
            loading={statusChangePending}
            onClick={() => {
              setCustomerRelease('Im Test');
              // changeStatus('Im Test');
              // setSearch((prev) => {
              //   prev.set('tab', '5');
              //   return prev;
              // });
            }}
          >
            Internen Test anfordern
          </Button>

          <Modal open={customerRelease !== null} onClose={() => setCustomerRelease(null)}>
            <div className="flex flex-wrap items-center gap-3  justify-between mb-3">
              <span className="">Freigabe bei ZMV anfordern</span>
              <Button.Action onClick={() => setCustomerRelease(null)}>
                <XMarkIcon className="w-4 h-4" />
              </Button.Action>
            </div>
            <div className="flex justify-end gap-3">
              <Smart.Form
                defaultValues={{
                  content: '',
                  subject: backends?.[membershipTemplate.backendId] + ': Abo-Freigabe erbeten',
                  sender: user?.email,
                  custom: false,
                  callbackUrl:
                    environment.env === 'live'
                      ? `https://admin.appsite.de${window.location.pathname}?tab=5`
                      : `https://admin-dev.appsite.de${window.location.pathname}?tab=5`,
                  to: '',
                }}
                onSubmit={(values) => {
                  withFeedback(
                    async () => {
                      await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
                        transition: 'Im Test',
                        email: values,
                      });
                      setCustomerRelease(null);
                      await mutateVersion();
                    },
                    {
                      success: 'Email wurde verschickt',
                      error: 'Email konnte nicht verschickt werden',
                    },
                  );
                }}
              >
                <Smart.Input type="text" name="subject" label="Betreff" rules={{ required: true }} />
                <Smart.Textarea name="content" label="Nachricht (Kommentar)" rules={{ required: true }} />
                <Smart.Combo name="to" label="Empfänger" customValue items={mailItems} rules={{ required: true }} />

                <Button $primary loading={pending} className="w-full">
                  Absenden
                </Button>
                <div className="flex gap-3">
                  <Button
                    className="w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      setCustomerRelease(null);
                    }}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Smart.Form>
            </div>
          </Modal>
        </>
      );
    case 'Im Test':
      return (
        <>
          {isDSBUser && (
            <Button
              loading={statusChangePending}
              onClick={() => {
                setCustomerRelease('Im Test');
              }}
            >
              Status-Mail erneut versenden
            </Button>
          )}
          <Button
            $primary
            loading={statusChangePending}
            $disabled={!formsValid || !canNext}
            onClick={() => {
              setCustomerRelease('Wartet auf Freigabe');
            }}
          >
            Freigabe anfordern
          </Button>

          <Modal open={customerRelease !== null} onClose={() => setCustomerRelease(null)}>
            <div className="flex flex-wrap items-center gap-3  justify-between mb-3">
              <span className="">Freigabe bei Ersteller anfordern</span>
              <Button.Action onClick={() => setCustomerRelease(null)}>
                <XMarkIcon className="w-4 h-4" />
              </Button.Action>
            </div>
            <div className="flex justify-end gap-3">
              <Smart.Form
                defaultValues={{
                  content: '',
                  subject: backends?.[membershipTemplate.backendId] + ': Abo-Freigabe erbeten',
                  sender: user?.email,
                  custom: false,
                  callbackUrl:
                    environment.env === 'live'
                      ? `https://admin.appsite.de${window.location.pathname}?tab=5`
                      : `https://admin-dev.appsite.de${window.location.pathname}?tab=5`,
                  to: '',
                }}
                onSubmit={(values) => {
                  withFeedback(
                    async () => {
                      await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
                        transition: customerRelease,
                        email: values,
                      });
                      setCustomerRelease(null);
                      await mutateVersion();
                    },
                    {
                      success: 'Email wurde verschickt',
                      error: 'Email konnte nicht verschickt werden',
                    },
                  );
                }}
              >
                <Smart.Input type="text" name="subject" label="Betreff" rules={{ required: true }} />
                <Smart.Textarea name="content" label="Nachricht (Kommentar)" rules={{ required: true }} />
                <Smart.Combo name="to" label="Empfänger" customValue items={mailItems} rules={{ required: true }} />

                <Button $primary loading={pending} className="w-full">
                  Absenden
                </Button>
                <div className="flex gap-3">
                  <Button
                    className="w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      setCustomerRelease(null);
                    }}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Smart.Form>
            </div>
          </Modal>
        </>
      );
    case 'Wartet auf Freigabe':
      return (
        <>
          {isDSBUser && (
            <Button
              loading={statusChangePending}
              onClick={() => {
                setCustomerRelease('Wartet auf Freigabe');
              }}
            >
              Status-Mail erneut versenden
            </Button>
          )}
          <Button $primary onClick={() => setStartSetLive(true)} $disabled={!canNext} loading={statusChangePending}>
            Abo freigeben & aktivieren
          </Button>
          <Modal open={customerRelease !== null} onClose={() => setCustomerRelease(null)}>
            <div className="flex flex-wrap items-center gap-3  justify-between mb-3">
              <span className="">Freigabe bei Ersteller anfordern</span>
              <Button.Action onClick={() => setCustomerRelease(null)}>
                <XMarkIcon className="w-4 h-4" />
              </Button.Action>
            </div>
            <div className="flex justify-end gap-3">
              <Smart.Form
                defaultValues={{
                  content: '',
                  subject: backends?.[membershipTemplate.backendId] + ': Abo-Freigabe erbeten',
                  sender: user?.email,
                  custom: false,
                  callbackUrl:
                    environment.env === 'live'
                      ? `https://admin.appsite.de${window.location.pathname}?tab=5`
                      : `https://admin-dev.appsite.de${window.location.pathname}?tab=5`,
                  to: '',
                }}
                onSubmit={(values) => {
                  withFeedback(
                    async () => {
                      await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
                        transition: customerRelease,
                        email: values,
                      });
                      setCustomerRelease(null);
                      await mutateVersion();
                    },
                    {
                      success: 'Email wurde verschickt',
                      error: 'Email konnte nicht verschickt werden',
                    },
                  );
                }}
              >
                <Smart.Input type="text" name="subject" label="Betreff" rules={{ required: true }} />
                <Smart.Textarea name="content" label="Nachricht (Kommentar)" rules={{ required: true }} />
                <Smart.Combo name="to" label="Empfänger" customValue items={mailItems} rules={{ required: true }} />

                <Button $primary loading={pending} className="w-full">
                  Absenden
                </Button>
                <div className="flex gap-3">
                  <Button
                    className="w-full"
                    onClick={(e) => {
                      e.preventDefault();
                      setCustomerRelease(null);
                    }}
                  >
                    Abbrechen
                  </Button>
                </div>
              </Smart.Form>
            </div>
          </Modal>

          <Modal open={startSetLive} onClose={() => setStartSetLive(false)}>
            {isDSBUser ? (
              <>
                <div className="flex justify-between">
                  <h2>Achtung du bist dabei das Abo Live zustellen</h2>
                  <XMarkIcon onClick={() => setStartSetLive(false)} className="w-4 cursor-pointer h-4" />
                </div>
                <p>Da du das Abo ohne Freigabe des Kunden live stellst gib bitte einen Kommentar ein</p>
                <div className="mt-6 pt-6 border-t">
                  <Input type="text" value={liveComment} onChange={(e) => setLiveComment(e.target.value)} />
                  <div className="flex justify-end mt-4">
                    <Button
                      $primary
                      placeholder="Kommentar eingeben"
                      $disabled={liveComment.length <= 3}
                      loading={livePending}
                      onClick={manualLive}
                    >
                      Manuell freigeben
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h2>Abo freigeben</h2>
                <p>
                  ch habe das Abo auf seine Richtigkeit geprüft und möchte es freigeben. Das Abo kann nach dieser
                  Freigabe in der Aktionsplanung eingeplant werden.
                </p>
                <div className="mt-6 pt-6">
                  <div className="flex justify-end mt-4">
                    <Button $primary placeholder="Kommentar eingeben" loading={livePending} onClick={manualLive}>
                      Freigabe erteilen
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Modal>
        </>
      );
    default:
      return null;
  }
};
