import { MembershipTemplateVersion } from '@entrecode/dm-types/types/MembershipTemplateVersion';
import { useActor } from '@xstate/react';
import EntryResource from 'ec.sdk/lib/resources/publicAPI/EntryResource';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router';
import { Comment } from '../../components/InternalComments';
import useFeedback from '../useFeedback';

export type ReadyState = {
  dsb: fullCheck;
  zmv: fullCheck;
  customer: fullCheck;
};

export type fullCheck = {
  web: {
    result: 'open' | 'feedback' | 'closed';
    vorteile: Comment['id'][];
    preis: Comment['id'][];
    overview: Comment['id'][];
  };
  legal: {
    result: 'open' | 'feedback' | 'closed';
    SEPA: Comment['id'][];
    PRIVACY: Comment['id'][];
    AGB: Comment['id'][];
  };
  booking: {
    result: 'open' | 'feedback' | 'closed';
    abo: Comment['id'][];
    konto: Comment['id'][];
  };
};

function changeDeepValue(obj, path, callback) {
  const lastKey = path[path.length - 1];
  const target = path.slice(0, -1).reduce((nestedObj, key) => nestedObj?.[key], obj);
  const value = target?.[lastKey];
  if (!target) return obj;
  target[lastKey] = callback(value);
  return obj;
}

export const defaultReadyState = (isAddon: boolean): ReadyState => ({
  dsb: {
    web: {
      result: isAddon ? 'closed' : 'open',
      vorteile: [],
      preis: [],
      overview: [],
    },
    legal: {
      result: 'open',
      SEPA: [],
      PRIVACY: [],
      AGB: [],
    },
    booking: {
      result: 'open',
      abo: [],
      konto: [],
    },
  },
  zmv: {
    web: {
      result: isAddon ? 'closed' : 'open',
      vorteile: [],
      preis: [],
      overview: [],
    },
    legal: {
      result: 'open',
      SEPA: [],
      PRIVACY: [],
      AGB: [],
    },
    booking: {
      result: 'open',
      abo: [],
      konto: [],
    },
  },
  customer: {
    web: {
      result: isAddon ? 'closed' : 'open',
      vorteile: [],
      preis: [],
      overview: [],
    },
    legal: {
      result: 'open',
      SEPA: [],
      PRIVACY: [],
      AGB: [],
    },
    booking: {
      result: 'open',
      abo: [],
      konto: [],
    },
  },
});

import { useInterpret } from '@xstate/react';
import { assign, createMachine } from 'xstate';
import { OMTContext } from './omtContext';

export const machine = createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QCcwEMIE8DKAXNuYAsmgMYAWAlgHZgB0sYuArgA4DEjuAamgDbM4AbQAMAXUShWAe1iVcladUkgAHogCMAZgDsdLSICcugBxaTIgGwAmSwBYdAGhCZEW64bobDJnQFYNAJ1DAMsAXzDnVAwcfEISChp6AHc0eQB9ADNpZHSaQmQyBSV2UmkAW3KwalxRCSQQGTli5Qb1BHcROms7Sz97Ew8fO2snF0Q7DS7DKYcdSy0NPpGIqPQsPAJiMipaOlSM7Nz8sELSFs445lg6lSb5RVbQdrsTDToRXREljTN5nucrgQk2msx080Wy2sqxA0Q2cW2iT2rFOclguHYECU9BoADdpABrHHUfFEgC0ACNSDo7Ljbg17i0VC9DHY6JYbAE-H5DMEtCE-IDENYRF1tHZdP1rP1DD5oZFYetYlsErt6CjkGiMadkDk6Kw+AQjuU6HjCWBKdTafSpLIHkpmYgfH5un0+sEPFpFlohQhRlo6KyrLYRSZjNoYXDlfEdkkGORpMkAKLIXXIdg2xp2pltTQiN50PxGSbcvyvUK+gtGXR2Yw6Ew2JbytYxTYxpH0BSkAnsVTorZ0NCZAoACiLIgAlOwo23EWq6F2CZnGY9HQg-ODAzS-NZbEsQiZfZZ84GJVodyIdLujOEYdRpBA4CoZwjVUk7tnV7mEGTLL6yS6sqyiCIg9BKSx+JGSqzm+excGwH7NF+zwTNYvrntY3QaP4or8jo+YLFBravrGewHLgWQ5HkNSnEUyFZkhDrfqM7zmDhhijDY+ENuhO5YThny8gRWhEfCKqkeqqKUOiiH2k8aiIP4LqiiIEqgVorx2HYvp2J8haTLyQlmHYfgmKJ0ZznGsAJsmqY5LJOYoQgGjYZYXhuvYWnzCEaHjH6lh6IEoZBLKJi9OZMESQulDdg59HtPyblhcM7gjMeHFjECx56NYXx+FoliGMWNIRBEQA */
  context: {
    values: undefined,
  },
  id: 'readyStateMachine',
  initial: 'setup',
  states: {
    setup: {
      on: {
        setValues: {
          target: 'wait_for_interaction',
          actions: {
            type: 'setInitialValues',
          },
        },
      },
    },

    wait_for_interaction: {
      on: {
        comment: {
          target: 'tick',
          actions: {
            type: 'handleComment',
          },
        },
        status: {
          target: 'tick',
          actions: {
            type: 'handleStatus',
          },
        },
      },
    },

    persist: {
      invoke: {
        src: 'persistData',
        id: 'invoke-bc74v',
        onDone: [
          {
            target: 'wait_for_interaction',
          },
        ],
        onError: [
          {
            target: 'showError',
          },
        ],
      },
    },

    showError: {
      always: {
        target: 'wait_for_interaction',
      },
    },

    tick: {
      after: {
        '500': 'persist',
      },
    },
  },
  tsTypes: {} as import('./useReadyState.typegen').Typegen0,
  schema: {
    events: {} as
      | { type: 'setValues'; initialValues: ReadyState }
      | { type: 'comment'; value: {}; name: string }
      | { type: 'status'; value: {}; tester: string; section: string },
    context: {} as { values: ReadyState },
  },
  predictableActionArguments: true,
  preserveActionOrder: true,
});

export const ReadyStateContext = createContext({});
export const ReadyStateProvider = ({ children }) => {
  const {
    selectedVersion,
    selectedVersionID,
    mutateVersion: mutate,
    isAddon,
    isLoadingVersion,
  } = useContext(OMTContext);

  const { withFeedback: withReadyStateFeedback, pending: pendingReadyState } = useFeedback();
  const relevant = useMemo(() => {
    return {
      'In Vorbereitung': 'dsb',
      'Im Test': 'zmv',
      'Wartet auf Freigabe': 'customer',
    }[selectedVersion?.draftStatus];
  }, [selectedVersion]);
  const data = useInterpret(machine, {
    actions: {
      setInitialValues: assign({ values: (context, event) => event.initialValues }),
      handleComment: assign((context, event) => {
        const pathKeys = [
          {
            'In Vorbereitung': 'dsb',
            'Im Test': 'zmv',
            'Wartet auf Freigabe': 'customer',
          }[selectedVersion.draftStatus],
          event.section || section,
          event.name,
        ];

        context.values[relevant][section].result = 'feedback';
        return {
          values: {
            ...changeDeepValue(context.values, pathKeys, () => [event.value]),
          },
        };
      }),
      handleStatus: (context, event) => {
        const pathKeys = [
          {
            'In Vorbereitung': 'dsb',
            'Im Test': 'zmv',
            'Wartet auf Freigabe': 'customer',
          }[selectedVersion.draftStatus],
          event.section || section,
          'result',
        ];
        const res = changeDeepValue(context.values, pathKeys, () => event.value);
        return assign({ values: res });
      },
    },
    services: {
      persistData: (context, event) => {
        return withReadyStateFeedback(
          async () => {
            if (!selectedVersion) return;
            selectedVersion.hectorSettings.readyState = { ...context.values };
            const res = await (selectedVersion as MembershipTemplateVersion & EntryResource).save();
            await mutate();
          },
          {
            success: 'Saved',
            error: 'Failed to save',
          },
        );
      },
    },
  });

  const [state, send] = useActor(data);

  const [search] = useSearchParams();
  const section = useMemo(() => {
    if (search.has('webpreview')) return 'web';
    if (search.has('legalpreview')) return 'legal';
    if (search.has('bookingpreview')) return 'booking';
    return '';
  }, [search]);

  const initMachine = () => {
    if (isLoadingVersion || !selectedVersionID) return;
    if (!selectedVersion) return;
    if (state.context.values === undefined) {
      if (!selectedVersion?.hectorSettings?.readyState) {
        send({ type: 'setValues', initialValues: defaultReadyState(isAddon) });
        return;
      }
      console.log(selectedVersion?.hectorSettings.readyState, 'res', '%c init', 'background: #222; color: #bada55');
      send({ type: 'setValues', initialValues: selectedVersion?.hectorSettings.readyState });
      return;
    }
  };

  useEffect(() => {
    initMachine();
  }, [selectedVersion, selectedVersionID, isLoadingVersion, send, state]);

  const feedbackForThisState = useMemo(() => {
    return {
      Entwurf: 'dsb',
      'In Vorbereitung': 'zmv',
      'Im Test': 'customer',
    }[selectedVersion?.draftStatus];
  }, [selectedVersion]);

  return (
    <ReadyStateContext.Provider
      value={{
        state,
        stateMachineService: data,
        initMachine,
        readyState: state.context.values || selectedVersion?.hectorSettings.readyState,
        setReadyState: send,
        relevant,
        feedbackForThisState,
        withReadyStateFeedback,
        pendingReadyState,
        get canNext() {
          return Object.entries(state.context.values?.[relevant] || {})
            .filter(([key]) => {
              if (isAddon && key === 'web') return false;
              return true;
            })
            .every(([_key, value]) => value.result === 'closed');
        },
      }}
    >
      {children}
    </ReadyStateContext.Provider>
  );
};
export default ReadyStateProvider;
