/* <Form onSubmit={onSubmit}> */
/*   <Input name="firstName" /> */
/*   <Input name="lastName" /> */
/*   <Select name="gender" options={["female", "male", "other"]} /> */
/*   <Input type="submit" value="Submit" /> */
/* </Form> */

import useBackends from '@/hooks/useBackends';
import { useClickAway } from '@/hooks/useClickAway';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { Combobox } from '@headlessui/react';
import { filterOptions } from 'ec.sdk/lib/resources/ListResource';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  DefaultValues,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormReturn,
  useForm,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import AddonSelect from './AddonSelect';
import { AssetPickerInput } from './AssetPickerInput';
import BackendSelect from './BackendSelect';
import Button from './Button';
import Calendar from './Calendar';
import { DateInput } from './CalendarInput';
import ClubSelect from './ClubSelect';
import { ColorPickerInput } from './ColorPicker';
import Form from './Form';
import Input from './Input';
import ProseEditor from './Prosemirror/ProseInput';
import SelectInput from './SelectInput';
import SimpleSelect from './SimpleSelect';
import { TagsInput } from './TagsPicker';
import { ToggleInput } from './Toggle';
import { RadioInput } from './RadioInput';
import { TextareaWithVariablesInput, WithVariablesInput } from './TextAreaWithVariables';

const Smart = {} as {
  Form: typeof sForm;
  Input: typeof sInput;
  Textarea: typeof sTextarea;
  File: typeof sFile;
  Date: typeof sDate;
  DateTime: typeof sDateTime;
  TagSelect: typeof sTags;
  Toggle: typeof sToggle;
  Radio: typeof sRadio;
  Watch: typeof sWatch;
  AddonSelect: typeof sAddonSelect;
  BackendSelect: typeof sBackendSelect;
  ClubSelect: typeof sClubSelect;
  Price: typeof sPrice;
  Select: typeof sSelect;
  Control: typeof sControl;
  Color: typeof sColor;
  Combo: typeof sCombo;
  Prose: typeof sProse;
  WithVariables: typeof sWithVariables;
  TextAreaWithVariables: typeof sTextAreaWithVariables;
};

type SmartProps<T> = {
  register?: (name: Path<T>, rules: RegisterOptions) => any;
  control?: Control<FieldValues>;
  name: Path<T>;
  rules?: RegisterOptions;
  label: string;
  type?: string;
  $first?: boolean;
  errors?: any;
  [key: string]: any;
};

function getNestedValue(obj, path) {
  // Handle empty or invalid inputs
  if (!obj || !path) return undefined;

  // Split the path into parts
  const parts = path.split('.');

  // Traverse the object
  let result = obj;
  for (const part of parts) {
    // Check if current level exists
    if (result === undefined || result === null) {
      return undefined;
    }
    // Move to next level
    result = result[part];
  }

  return result;
}

function sForm<T extends { [key: string]: any }>({
  form,
  defaultValues,
  children,
  $dense,
  onSubmit,
  onChange,
}: {
  defaultValues?: DefaultValues<T>;
  form?: UseFormReturn<T>;
  children: Array<React.ReactNode>;
  $dense?: boolean;
  onSubmit: (data: T) => void;
  onChange?: (data: T) => void;
}) {
  const ownForm = useForm({ defaultValues, mode: 'onChange', reValidateMode: 'onChange' });
  const { register, control, watch, formState, handleSubmit } = form || ownForm;
  const formErrors = formState.errors;
  const change = watch();

  useEffect(() => {
    onChange?.(change);
  }, [change]);

  const injectPropsIntoChildren = useCallback(
    (children: React.ReactNode): React.ReactNode => {
      const processedChildren = React.Children.toArray(children);

      return processedChildren.map((child, i) => {
        if (!React.isValidElement(child) || !child.props) return child;

        let childProps: Record<string, any> = {};

        if (child.props.name) {
          childProps = {
            ...(child.props as any), // Assume any prop type
            register,
            control,
            watch,
            formState,
            $first: i === 0,
            $dense,
            errors: getNestedValue(formErrors, child.props.name),
          };
        } else {
          childProps = {
            ...(child.props as any), // Assume any prop type
          };
        }

        if (childProps.children) {
          childProps.children = injectPropsIntoChildren(childProps.children);
        }

        return React.cloneElement(child, childProps);
      });
    },
    [formErrors, register, control, watch],
  );

  return (
    <form
      onSubmit={handleSubmit((e) => {
        console.log(e);
        onSubmit(e);
      })}
    >
      <Form>{injectPropsIntoChildren(children)}</Form>
    </form>
  );
}

Smart.Form = sForm;

function sWithVariables<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  render,
  variables,
  ...rest
}: SmartProps<T>) {
  return (
    <>
      <Form.Item $dense={$dense} $first={$first}>
        <Form.Item.Label>{label}</Form.Item.Label>
        <Form.Item.Body>
          <WithVariablesInput control={control} name={name} variables={variables} rules={rules} />
          <SmartError errors={errors} />

          {subline && <small>{subline}</small>}
        </Form.Item.Body>
      </Form.Item>
    </>
  );
}

Smart.WithVariables = sWithVariables;

function sTextAreaWithVariables<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  render,
  variables,
  ...rest
}: SmartProps<T>) {
  return (
    <>
      <Form.Item $dense={$dense} $first={$first}>
        <Form.Item.Label>{label}</Form.Item.Label>
        <Form.Item.Body>
          <TextareaWithVariablesInput {...rest} control={control} name={name} variables={variables} rules={rules} />
          <SmartError errors={errors} />

          {subline && <small>{subline}</small>}
        </Form.Item.Body>
      </Form.Item>
    </>
  );
}

Smart.TextAreaWithVariables = sTextAreaWithVariables;

function sControl<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  render,
  ...rest
}: SmartProps<T>) {
  return (
    <>
      <Form.Item $dense={$dense} $first={$first}>
        <Form.Item.Label>{label}</Form.Item.Label>
        <Form.Item.Body>
          <Controller rules={rules} control={control} name={name} render={render} />
          {errors && <small className="text-xs text-red-500">{errors.message || errors.type}</small>}
          {subline && <small>{subline}</small>}
        </Form.Item.Body>
      </Form.Item>
    </>
  );
}

Smart.Control = sControl;

export function sInput<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  ...rest
}: SmartProps<T>) {
  if (rest.unless) {
    const unless = watch(rest.unless);
    if (unless) return null;
  }

  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Input type={type} {...register(name, rules)} {...rest} />
        <SmartError errors={errors} />
        {subline && <small>{subline}</small>}
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Input = sInput;

export function sCombo<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  items,
  ...rest
}: SmartProps<T>) {
  if (rest.unless) {
    const unless = watch(rest.unless);
    if (unless) return null;
  }
  const [query, setQuery] = useState('');

  const filteredItems =
    query === ''
      ? items
      : items.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Combobox value={field.value} onChange={field.onChange}>
              <Combobox.Input
                onChange={(event) => setQuery(event.target.value)}
                className="bg-white dark:bg-gray-700 relative w-full border border-gray-300 dark:border-gray-600 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <Combobox.Options className="absolute z-[1200] min-w-[200px] mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {query.length > 0 && (
                  <Combobox.Option
                    value={query}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white dark:text-gray-300 bg-indigo-600' : 'text-gray-900 dark:text-gray-400 ',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                  >
                    {query}
                  </Combobox.Option>
                )}
                {filteredItems.map((item) => (
                  <Combobox.Option
                    key={item.value}
                    value={item.value}
                    className={({ active }) =>
                      classNames(
                        item.hidden && 'hidden',
                        active ? 'text-white dark:text-gray-300 bg-indigo-600' : 'text-gray-900 dark:text-gray-400 ',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                  >
                    {item.label}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Combobox>
          )}
        />

        <SmartError errors={errors} />

        {subline && <small>{subline}</small>}
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Combo = sCombo;

export function sTextarea<T>({
  // provided by Smart.Form
  register = (_name, _rules) => ({}),
  name,
  label,
  rules = {},
  // provided by Smart.Form
  $first = false,
  $dense = false,
  // provided by Smart.Form
  errors = {} as any,
  watch,
  ...rest
}: SmartProps<T>) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Input.Textarea {...register(name, rules)} {...rest} />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Textarea = sTextarea;

export function sProse<T>({
  // provided by Smart.Form
  register = (_name, _rules) => ({}),
  name,
  label,
  rules = {},
  control,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  // provided by Smart.Form
  errors = {} as any,
  watch,
  ...rest
}: SmartProps<T>) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return <ProseEditor value={field.value} onChange={field.onChange} />;
          }}
        />

        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Prose = sProse;

export function sWatch({
  // provided by Smart.Form
  watch,
  control,
  name,
  render,
}: {
  watch?: (name: string) => any;
  name: string;
  render: (value: any) => ReactNode;
}) {
  if (!watch) {
    return null;
  }
  const value = watch(name);
  return <>{render(value)}</>;
}
Smart.Watch = sWatch;

type TagProps = {
  model: string;
  filter: filterOptions;
};
export function sTags<T>({
  // provided by Smart.Form
  // register = (_name: string) => ({}),
  control,
  name,
  label,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  model,
  allowNew = true,
  // provided by Smart.Form
  errors = {} as any,
  ...rest
}: SmartProps<T> & TagProps) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <TagsInput control={control} name={name} model={model} allowNew={allowNew} {...rest} />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.TagSelect = sTags;

export function sToggle<T>({
  // provided by Smart.Form
  // register = (_name: string) => ({}),
  control,
  name,
  label,
  watch,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  primaryLabel,
  // provided by Smart.Form
  errors,
  ...rest
}: SmartProps<T>) {
  if (rest.unless) {
    const unless = watch(rest.unless);
    if (unless) return null;
  }

  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <ToggleInput control={control} name={name} {...rest} label={primaryLabel} />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Toggle = sToggle;

export function sRadio<T>({
  // provided by Smart.Form
  // register = (_name: string) => ({}),
  control,
  name,
  label,
  watch,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  // provided by Smart.Form
  errors,
  ...rest
}: SmartProps<T>) {
  if (rest.unless) {
    const unless = watch(rest.unless);
    if (unless) return null;
  }

  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <RadioInput control={control} name={name} {...rest} />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Radio = sRadio;

export function sAddonSelect<T>({
  // provided by Smart.Form
  // register = (_name: string) => ({}),
  control,
  name,
  label,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  // provided by Smart.Form
  errors = {},
  filterBackend,
  rules,
  ...rest
}: SmartProps<T>) {
  const { backends } = useBackends();
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        {Object.keys(backends || {})
          .filter((v) => (filterBackend ? filterBackend(v) : true))
          ?.map((backend) => (
            <div key={backend} className="flex gap-3 mb-3 items-baseline">
              <span className="w-full max-w-[15%] text-xs">{backends[backend]}</span>
              <Controller
                control={control}
                rules={rules}
                name={`${name}.${backend}` as Path<T>}
                render={({ field }) => (
                  <div className="flex flex-col gap-2 w-full">
                    <AddonSelect
                      value={field.value}
                      className="w-full"
                      onChange={field.onChange}
                      filter={(addon) => addon.backendId === backend}
                    />
                    {errors[backend] && (
                      <small className="text-xs text-red-500">{errors[backend].message || errors[backend].type}</small>
                    )}
                  </div>
                )}
              />
            </div>
          ))}
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.AddonSelect = sAddonSelect;

export function sDate({
  // provided by Smart.Form
  // register = (_name: string) => ({}),
  control = {},
  name,
  label,
  rules = {},
  // provided by Smart.Form
  $first = false,
  $dense = false,
  // provided by Smart.Form
  errors = {} as any,
  ...rest
}) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <DateInput control={control} name={name} rules={rules} {...rest} format="YYYY-MM-DD" />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Date = sDate;

export function sDateTime({
  // provided by Smart.Form
  register = (_name: string) => ({}),
  control = {},
  name,
  label,
  rules = {},
  // provided by Smart.Form
  $first = false,
  $dense = false,
  customMinutes,
  // provided by Smart.Form
  errors = {} as any,
  watch = () => null,
  ...rest
}) {
  const [show, setShow] = useState(false);
  const ref = useClickAway(() => {
    setShow(false);
  });
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <div ref={ref} className={classNames(rest?.disabled && 'cursor-not-allowed pointer-events-none')}>
          <div className="flex items-center gap-3">
            <Input
              readOnly
              onFocus={() => setShow(true)}
              value={watch(name) ? dayjs(watch(name)).format('DD.MM.YYYY HH:mm') : ''}
              disabled={rest?.disabled}
              placeholder="unbegrenzt"
            />
          </div>
          {show && (
            <Controller
              control={control}
              name={name}
              render={({ field }) => {
                const value = dayjs(field.value);
                return (
                  <>
                    <Calendar
                      value={value.format('YYYY-MM-DD')}
                      onChange={(v) => {
                        field.onChange(v.toISOString());
                      }}
                    />

                    <div className="rounded-lg shadow-lg mt-3 p-2 px-9 ">
                      <div>Uhrzeit</div>
                      <div className="flex items-center w-full  gap-2">
                        <SimpleSelect
                          placeholder="Stunde"
                          value={value.hour()}
                          onChange={(v) => {
                            field.onChange(value.hour(v).toISOString());
                          }}
                          items={Array(24)
                            .fill(0)
                            .map((_, i) => ({ label: padNumber(i), value: i }))}
                        />
                        :
                        <SimpleSelect
                          placeholder="Minute"
                          value={value.minute()}
                          onChange={(v) => {
                            field.onChange(value.minute(v).toISOString());
                          }}
                          items={(
                            customMinutes ||
                            Array(60)
                              .fill(0)
                              .map((_, i) => i)
                          ).map((v, i) => ({ label: padNumber(v), value: v }))}
                        />
                      </div>
                      <Button className="w-full mt-3" onClick={() => field.onChange(null)}>
                        Leeren
                      </Button>
                    </div>
                  </>
                );
              }}
            />
          )}
        </div>
        {errors && <small className="text-xs text-red-500">{errors.message}</small>}
      </Form.Item.Body>
    </Form.Item>
  );
}
function padNumber(n: number) {
  return n < 10 ? `0${n}` : `${n}`;
}

Smart.DateTime = sDateTime;

export function sFile({
  // provided by Smart.Form
  control = {},
  name,
  label,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  // provided by Smart.Form
  errors = {} as any,
  group,
  watch,
  dsbLayer,
  // allow stock search
  stock = false,
  ...rest
}) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <AssetPickerInput stock={stock} dsbLayer={dsbLayer} control={control} group={group} name={name} {...rest} />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.File = sFile;

export function sBackendSelect<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  name,
  rules = {},
  label,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  ...rest
}: SmartProps<T>) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => <BackendSelect {...rest} value={field.value} onChange={field.onChange} />}
        />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.BackendSelect = sBackendSelect;

export function sClubSelect<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  name,
  rules = {},
  label,
  type = 'text',
  multi = true,
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  ...rest
}: SmartProps<T>) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => <ClubSelect {...rest} multi={multi} value={field.value} onChange={field.onChange} />}
        />
        <SmartError errors={errors} />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.ClubSelect = sClubSelect;

function sPrice<T>({
  // provided by Smart.Form
  // register = (_name: string) => ({}),
  control,
  name,
  label,
  rules,
  // provided by Smart.Form
  $first = false,
  $dense = false,
  cents = false,
  // provided by Smart.Form
  errors = {} as any,
  ...rest
}: SmartProps<T>) {
  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field }) => (
            <NumericFormat
              value={cents ? field.value / 100 : field.value}
              placeholder="–"
              // getInputRef={ref => { if (ref) ref.value = hidden.current?.value }}
              onValueChange={(v) => {
                cents ? field.onChange(v.floatValue! * 100) : field.onChange(v.floatValue as any);
              }}
              className="block w-full dark:bg-gray-700 rounded-md border-gray-300 dark:border-gray-500  focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              decimalSeparator=","
              suffix={' €'}
              decimalScale={2}
            />
          )}
        />
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Price = sPrice;

export function sSelect<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  items,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  ...rest
}: SmartProps<T>) {
  if (rest.unless) {
    const unless = watch(rest.unless);
    if (unless) return null;
  }

  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <SelectInput rules={rules} {...rest} control={control} name={name} items={items} />
        <SmartError errors={errors} />
        {subline && <small>{subline}</small>}
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Select = sSelect;

function sColor<T>({
  /* provided by Smart.Form */
  register = (_name, _rules) => ({}),
  control,
  watch,
  name,
  rules = {},
  label,
  subline,
  type = 'text',
  /* provided by Smart.Form */
  $first = false,
  $dense = false,
  /* provided by Smart.Form */
  errors = {} as any,
  ...rest
}: SmartProps<T>) {
  if (rest.unless) {
    const unless = watch(rest.unless);
    if (unless) return null;
  }

  return (
    <Form.Item $dense={$dense} $first={$first}>
      <Form.Item.Label>{label}</Form.Item.Label>
      <Form.Item.Body>
        <ColorPickerInput control={control} name={name} {...rest} rules={rules} />
        <SmartError errors={errors} />
        {subline && <small>{subline}</small>}
      </Form.Item.Body>
    </Form.Item>
  );
}
Smart.Color = sColor;

const SmartError = ({ errors }: { errors: any }) => {
  return (
    errors && (
      <small className="text-xs text-red-500">{errors.type === 'required' ? 'Pflichtfeld' : errors.message}</small>
    )
  );
};

export default Smart;
