import * as Sentry from '@sentry/react';
import AccountResource from 'ec.sdk/lib/resources/accounts/AccountResource';
import { useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import useSdk from './useSdk';

function useUser() {
  const { api, accounts } = useSdk();

  const { data: account } = useSWRImmutable<AccountResource>(accounts && 'sdk.accounts.me', () => accounts.me());

  const checkPermission = async (perm) => api?.checkPermission(perm); // TODO: whats the difference to hasPermission?

  useEffect(() => {
    if (account) {
      Sentry.setUser({
        id: account?.email,
      });
    }
  }, [account]);

  const checkAccountPermission = async (perm: string) => {
    if (perm.includes(':eva:')) console.warn(`You are using an deprecated EVA permission ${perm}`);
    if (perm.includes('?')) {
      return account.queryPermissions(perm).length > 0;
    } else {
      return account.checkPermission(perm);
    }
  };

  const getModelID = (id) => api?.models.find((m) => m.title === id)?.modelID;

  // account permissions independent of datamanager
  const { data: globalPermissions } = useSWRImmutable(
    account ? [account.accountID, 'globalPermissions'] : null,
    async () => {
      return {
        canEditDSBLayers:
          (await checkAccountPermission('dm:e80ccb08-0fb5-42ec-8362-0414a4a4ca8d:model:entries:franchise_config')) ||
          (await checkAccountPermission('dm:567bb2c3-f233-4062-9b12-99d5aed21ad0:model:entries:franchise_config')),
        canEditReleaseNotes:
          (await checkAccountPermission(
            'dm:e80ccb08-0fb5-42ec-8362-0414a4a4ca8d:model:entries:ce15a5d4-29e4-4ce8-a545-1bb30e66749c',
          )) ||
          (await checkAccountPermission(
            'dm:567bb2c3-f233-4062-9b12-99d5aed21ad0:model:entries:86112d4e-9090-4890-a556-36767e0cd2e7',
          )),
        canCreate: await checkAccountPermission('hec:appsite-create'),
        canInvite: await checkAccountPermission('acc:invite:createWithGroups'),
        canMigrate: await checkAccountPermission('hec:render-migration'),
        // canMigrate: false,
        //canUpdateRoles: await checkAccountPermission('dsb-layer:rolesAndPolicies'), // das sind redakteure !
        isEcAdmin: await checkAccountPermission('hec:ec-admin'),
        isSuperAdmin: await checkAccountPermission('hec:super-admin'),
        // isSuperAdmin: false,
        canHecRenderView: await checkAccountPermission('hec:render:view'),
        canHecRenderEdit: await checkAccountPermission('hec:render:edit'),
      };
    },
  );
  const useCanSeeStats = (dmID) =>
    useSWRImmutable(
      account ? [account.accountID, 'useCanSeeStats', dmID] : null,
      async () =>
        (await checkAccountPermission('hec:ec-admin')) || (await checkAccountPermission(`dm:${dmID}:marketing:stats`)),
    );

  // datamanager related account permissionso
  const { data: accountPermissions } = useSWRImmutable(
    api?.models?.length > 0 && api?.dataManagerID && account
      ? [account?.accountID, api?.dataManagerID, 'permissions']
      : null,
    async () => {
      const dmID = api?.dataManagerID;
      return {
        canDM: await checkAccountPermission(`dm:${dmID}:?`),
        canHistory: await checkAccountPermission(`dm:${dmID}:model:entries:?:history`),
        canEditApp: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('app_article')}:edit`),
        canAssetGroups: await checkAccountPermission(`dm:${dmID}:assetgroup:*:read,assets`),
        canEditAssets: await checkAccountPermission(`dm:${dmID}:asset:list,upload`),

        /** @deprecated */
        canEditEvaAbos: await checkAccountPermission(`dm:${dmID}:eva:abo`),

        canStream: await checkAccountPermission(`dm:${dmID}:streaming:manage`),
        canMarketing: await checkAccountPermission(`dm:${dmID}:marketing:push`),
        canPublicConfig: await checkAccountPermission(`dm:${dmID}:edit`),

        canRelease: await checkAccountPermission(`app:${api.config.appID}:build`),
        canReadStats: (await account.queryPermissions(`dm:${dmID}:marketing:stats:?`)).length > 0,
        canReview: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('app_settings')}:edit`),
        canUpselling: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('upselling')}:edit`),
        canEditAddonConfig: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('addon_config')}:edit`),
        canEditWebsite: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('site')}:edit`),
        canConsentCampaign: await checkAccountPermission(
          `dm:${dmID}:model:entries:${getModelID('consent_campaign')}:edit`,
        ),
        canConsentEntries: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('consent_entry')}:edit`),
        canEditMembership: await checkAccountPermission(
          `dm:${dmID}:model:entries:${getModelID('membership_config')}:edit`,
        ),
        canEditSchedule: await checkAccountPermission(
          `dm:${dmID}:model:entries:${getModelID('membership_schedule')}:edit`,
        ),
        canMarketingReports: await checkAccountPermission(
          `dm:${dmID}:model:entries:${getModelID('marketing_reports')}:edit`,
        ),
        canDiscounts: await checkAccountPermission(`dm:${dmID}:model:entries:${getModelID('discount_campaign')}:edit`),

        canManageAbos: await checkAccountPermission(`dm:${dmID}:hec:abo-management`),
        isDSBAdmin: await checkAccountPermission('hec:dsb-admin'),
        canMembershipRequests: await checkAccountPermission(`dm:${dmID}:member:requests:?`),
        canHoAccounts: await checkAccountPermission(`dm:${dmID}:member:accounts:?`),
        canHoAccountHistory: await checkAccountPermission(`dm:${dmID}:member:accounts:history`),
        ho: {
          canMembershipRequests: await checkAccountPermission(`dm:${dmID}:member:requests:?`),

          membership: {
            abos: await checkAccountPermission(`dm:${dmID}:club:?:membership:abos`),
            addons: await checkAccountPermission(`dm:${dmID}:club:?:membership:addons`),
            vouchers: await checkAccountPermission(`dm:${dmID}:club:?:membership:vouchers`),
            mails: await checkAccountPermission(`dm:${dmID}:club:?:membership:mails`),
            terms: await checkAccountPermission(`dm:${dmID}:club:?:membership:terms`),
          },

          member: {
            accounts: await checkAccountPermission(`dm:${dmID}:club:?:member:accounts`),
            requests: await checkAccountPermission(`dm:${dmID}:club:?:member:requests`),
            addonRequests: await checkAccountPermission(`dm:${dmID}:club:?:member:addon-requests`),
            cancellations: await checkAccountPermission(`dm:${dmID}:club:?:member:cancellations`),
          },

          canMembershipTemplates: await checkAccountPermission(`dm:${dmID}:ho:membershiptemplates`),

          canUsers: await checkAccountPermission(`dm:${dmID}:member:accounts:?`),
          canDeleteUsers: await checkAccountPermission(`dm:${dmID}:member:accounts:delete-conditional`),
          /* Newsletter subscription */
          canWriteNewsletter: await checkAccountPermission(`dm:${dmID}:member:accounts:edit-conditional:newsletter`),
          canReadNewsletter: await checkAccountPermission(`dm:${dmID}:member:accounts::view-conditional:newsletter`),
          /* User email edit */
          canWriteUserMail: await checkAccountPermission(`dm:${dmID}:member:accounts:edit-conditional:email`),
          /* User activation edit */
          canWriteUserActivation: await checkAccountPermission(
            `dm:${dmID}:member:accounts:edit-conditional:activation`,
          ),

          /* Zahlungsanbieter */
          canReadSecrets: await checkAccountPermission(`dm:${dmID}:ho:secrets:read`),
          canWriteSecrets: await checkAccountPermission(`dm:${dmID}:ho:secrets:write`),
          canDeleteSecrets: await checkAccountPermission(`dm:${dmID}:ho:secrets:delete`),

          /* Kommunikationsportal */
          canSendPush: await checkAccountPermission(`dm:${dmID}:ho:communications:send:push`),
          canSendEmail: await checkAccountPermission(`dm:${dmID}:ho:communications:send:email`),
          canSendInbox: await checkAccountPermission(`dm:${dmID}:ho:communications:send:inbox`),
          canReadTargetGroupCount: await checkAccountPermission(`dm:${dmID}:ho:communications:read:targetgroup`),
          canSeeTargetGroup: await checkAccountPermission(`dm:${dmID}:model:entries:push_target_group:list`),
          canSeePushCampaign: await checkAccountPermission(`dm:${dmID}:model:entries:push_campaign:list`),
        },
      };
    },
    {
      revalidateOnFocus: false,
    },
  );

  // public api permissions
  const { data: publicPermissions } = useSWRImmutable(
    api ? ['publicPermissions', api?.dataManagerID] : null,
    async () => ({
      canWeb: await checkPermission('site:post:*'),
      canHecInfo: await checkPermission('slider:post:*'),
      canApp: await checkPermission('app_article:post:*'),
      canCourseTypes: await checkPermission('course_type_config:post:*'),
      canAppointments: await checkPermission('appointment_config:post:*'),
      canEmployees: await checkPermission('employee_config:post:*'),
      canVouchers: await checkPermission('voucher_config:post:*'),
      canAddons: await checkPermission('addon_config:post:*'),
      canCourses: await checkPermission('course_config:post:*'),
      canClubs: await checkPermission('site:post:*'),
    }),
  );

  const canEditSettings =
    publicPermissions &&
    (publicPermissions.canCourseTypes ||
      publicPermissions.canAppointments ||
      publicPermissions.canEmployees ||
      publicPermissions.canVouchers ||
      publicPermissions.canAddons ||
      publicPermissions.canCourses ||
      publicPermissions.canClubs);

  const userData = {
    email: account?.email,
    nativePermissisons: account?.nativePermissions,
    ...publicPermissions,
    ...accountPermissions,
    ...globalPermissions,
    canEditSettings,
    useCanSeeStats,
  };
  return userData;
}
export default useUser;
