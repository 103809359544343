/* eslint-disable react/no-unstable-nested-components */
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import Button from '@/components/Button';
import ConfirmDelete from '@/components/ConfirmDelete';
import Form from '@/components/Form';
import { useGlobalContractPreviewImage } from '@/components/GlobalContract/useGlobalContractPreview';
import Info from '@/components/Info';
import Input from '@/components/Input';
import { InternalComments } from '@/components/InternalComments';
import Layout from '@/components/Layout';
import Modal from '@/components/Modal';
import { BookingPreview } from '@/components/OnlineMembershipTemplates/BookingPreview';
import CodesForm from '@/components/OnlineMembershipTemplates/CodesForm';
import ConsentSelect from '@/components/OnlineMembershipTemplates/ConsentSelect';
import { ContractForm } from '@/components/OnlineMembershipTemplates/ContractForm';
import { CreateDraftButton } from '@/components/OnlineMembershipTemplates/CreateDraftButton';
import { DeleteButton } from '@/components/OnlineMembershipTemplates/DeleteButton';
import { HectorForm } from '@/components/OnlineMembershipTemplates/HectorForm';
import { hectorIntervals } from '@/components/OnlineMembershipTemplates/hectorIntervals';
import { NextButton } from '@/components/OnlineMembershipTemplates/NextButton';
import { PDFPreviewModal, usePDFPreview } from '@/components/OnlineMembershipTemplates/PDFPreviewModal';
import { PricesForm } from '@/components/OnlineMembershipTemplates/PricesForm';
import ReleaseForm from '@/components/OnlineMembershipTemplates/ReleaseForm';
import SellingForm from '@/components/OnlineMembershipTemplates/SellingForm';
import { WebPreview } from '@/components/OnlineMembershipTemplates/WebPreview';
import Section from '@/components/Section';
import SelectInput from '@/components/SelectInput';
import Sidebar from '@/components/Sidebar';
import SimpleSelect from '@/components/SimpleSelect';
import Smart from '@/components/SmartForm';
import Spinner from '@/components/Spinner';
import Tab from '@/components/Tabs';
import environment from '@/environment';
import { OMTContext, WithOMT } from '@/hooks/onlineMembershipTemplates/omtContext';
import { ReadyStateContext } from '@/hooks/onlineMembershipTemplates/useReadyState';
import { useGeneralMembershipTemplates } from '@/hooks/useAppsite';
import useBackends from '@/hooks/useBackends';
import { useDSB } from '@/hooks/useDSB';
import useFeedback from '@/hooks/useFeedback';
import usePermissionQuery from '@/hooks/usePermissionQuery';
import useSdk from '@/hooks/useSdk';
import useUser from '@/hooks/useUser';
import { Preview } from '@/routes/TemplateDesigner/components/Preview';
import { classNames } from '@/util/classNames';
import dayjs from '@/util/dayjs';
import { translate } from '@/util/translate';
import { MembershipTemplate } from '@entrecode/dm-types/types/MembershipTemplate';
import { MembershipTemplateVersion } from '@entrecode/dm-types/types/MembershipTemplateVersion';
import { formatPeriod, formatPrice, getHumanReadableAboDefinition } from '@entrecode/hectorone-helpers';
import { DocumentIcon, MinusCircleIcon } from '@heroicons/react/24/outline';
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import AssetResource from 'ec.sdk/lib/resources/datamanager/AssetResource';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router';
import CodesTable from './Discounts/components/CodesTable';

enum Tabs {
  Overview = 0,
  Prices = 1,
  Template = 2,
  Hector = 3,
  Consent = 4,
  Comments = 5,
  Test = 6,
  Selling = 7,
  Codes = 8,
}

export const TabsContext = createContext<{
  canChange: boolean;
  setCanChange: (canChange: boolean) => void | any;
}>({
  canChange: true,
  setCanChange: (canChange: boolean) => {},
});

const OnlineMembershipTemplateDetail = ({ type = 'contract' }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    membershipTemplate,
    selectedVersion,
    setSelectedVersion,
    changeStatus: change,
    statusChangePending,
    completeState,
    isLoading,
    isLoadingVersionState,
    userCan,
    isDSBUser,
    templateID,
    isVoucher,
    isAddon,
    mutate,
  } = useContext(OMTContext);

  const activeTab = Number(searchParams.get('tab')) || (templateID ? Tabs.Overview : Tabs.Prices);
  const setActiveTab = (tab: Tabs) => setSearchParams({ tab: String(tab) }, { replace: true });

  const [backToCustomer, setBackToCustomer] = useState(null);
  const user = useUser();
  const changeStatus = async (status: any) => {
    if (status === 'Entwurf') {
      setBackToCustomer(status);
      return;
    }
    await change(status);
    mutate();
  };

  let perm = 'abos';
  if (isVoucher) perm = 'vouchers';
  if (isAddon) perm = 'addons';
  const { data: canEditPerm } = usePermissionQuery('membership:' + perm + ':edit');

  const { data: canDraftPerm } = usePermissionQuery('membership:' + perm + ':draft');

  const [canChange, setCanChange] = useState(true);
  const [confirmChange, setConfirmChange] = useState<Tabs | null>(null);
  const changeTab = (tab: Tabs) => {
    if (canChange) {
      // setSearchParams(`tab=${tab}`, { replace: true });
      return setActiveTab(tab);
    }
    setConfirmChange(tab);
  };

  const allComplete = Object.values(completeState || {}).every((c) => c);

  const PrevButton = useCallback(() => {
    switch (selectedVersion?.draftStatus) {
      case 'Entwurf':
        return null;
      case 'In Vorbereitung':
        return (
          <Button $secondary onClick={() => changeStatus('Entwurf')} loading={statusChangePending}>
            Korrektur an Abo nötig
          </Button>
        );
      case 'Im Test':
        return (
          <Button
            $secondary
            onClick={async () => {
              selectedVersion.hectorSettings.readyState = {
                pricesReady: false,
                templateReady: false,
                hectorReady: false,
              };
              changeTab(Tabs.Overview);
              await changeStatus('In Vorbereitung');
            }}
            loading={statusChangePending}
          >
            Feedback absenden
          </Button>
        );
      case 'Wartet auf Freigabe':
        return (
          <Button $secondary onClick={() => changeStatus('Im Test')} loading={statusChangePending}>
            Abo nicht freigeben
          </Button>
        );
      default:
        return null;
    }
  }, [selectedVersion, statusChangePending, changeStatus]);

  const [search, setSearch] = useSearchParams() as any;
  const { previewPending, handlePreview, setPreview, preview } = usePDFPreview(
    type === 'addon'
      ? 'GlobalAddon'
      : type === 'voucher'
      ? 'GlobalVoucher'
      : type === 'contract'
      ? 'GlobalContract'
      : undefined,
  );
  const { readyState, canNext } = useContext(ReadyStateContext) as any;
  const navigate = useNavigate();
  const readyFor = (selectedVersion?.draftStatus === 'Entwurf' && completeState?.prices) || false;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (canEditPerm?.length >= 1) {
      search.has('saved') && setShowModal(readyFor);
    }
  }, [readyFor, search, canEditPerm]);

  const { post } = useDSB();
  const { withFeedback, pending } = useFeedback();

  const mailItems = useMemo(() => {
    if (!selectedVersion) return [];

    const mails = new Set();
    if (selectedVersion.comments) {
      for (const comment of selectedVersion.comments) {
        if (comment?.metadata?.email) mails.add(comment.metadata.email);
      }
    }
    return Array.from(mails).map((email) => ({ label: email, value: email }));
  }, [selectedVersion]);

  const [testBooking, setTestBooking] = useState('');

  function bookTestMembership() {
    withFeedback(
      async () => {
        const testBooking = await post(`admin/membership-templates/version/${selectedVersion.id}/test-hector`, {});
        setTestBooking(testBooking.id);
      },
      {
        success: 'Testbuchung wurde angestoßen...',
        error: (e) => `Fehler beim Versenden ${e}`,
      },
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>OnlineMembership Template | Appsite Admin</title>
      </Helmet>

      <Section>
        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <div className="flex flex-wrap items-center gap-3  justify-between mb-3">
            <span className="">
              Dein Abo entspricht Deinem Club-Standard und ist bereit für die Hector- und Vertragsanlage?
            </span>
            <Button.Action onClick={() => setShowModal(false)}>
              <XMarkIcon className="w-4 h-4" />
            </Button.Action>
          </div>
          <div className="flex justify-end gap-3">
            <Button
              $primary
              loading={pending}
              onClick={() => {
                changeStatus('In Vorbereitung');
                setShowModal(false);
              }}
            >
              Ja, zur Anlage freigeben
            </Button>
            {/* <Button $secondary onClick={() => setShowModal(false)}> */}
            {/*   Nein, Abo-Anlage weiter bearbeiten */}
            {/* </Button> */}
            <Button $secondary onClick={() => navigate(`../?backend=${membershipTemplate?.backendId}`)}>
              Nein, Entwurf später abschließen
            </Button>
          </div>
        </Modal>

        <Modal open={backToCustomer !== null} onClose={() => setBackToCustomer(null)}>
          <div className="flex flex-wrap items-center gap-3  justify-between mb-3">
            <span className="">Entwurf-Überarbeitung an Ersteller senden</span>
            <Button.Action onClick={() => setBackToCustomer(null)}>
              <XMarkIcon className="w-4 h-4" />
            </Button.Action>
          </div>
          <div className="flex justify-end gap-3">
            <Smart.Form
              defaultValues={{
                content: '',
                subject: 'Abo-Entwurf geändert',
                sender: user?.email,
                custom: false,
                callbackUrl:
                  environment.env === 'live'
                    ? `https://admin.appsite.de${window.location.pathname}?tab=5`
                    : `https://admin-dev.appsite.de${window.location.pathname}?tab=5`,
                to: '',
              }}
              onSubmit={(values) => {
                withFeedback(
                  async () => {
                    await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
                      transition: 'MailEntwurf',
                      email: values,
                    });
                    setBackToCustomer(null);
                    setSearch((prev) => {
                      prev.set('tab', 1);
                      return prev;
                    });
                  },
                  {
                    success: 'Email wurde verschickt',
                    error: 'Email konnte nicht verschickt werden',
                  },
                );
              }}
            >
              <Smart.Input type="text" name="subject" label="Betreff" rules={{ required: true }} />
              <Smart.Textarea name="content" label="Nachricht (Kommentar)" rules={{ required: true }} />
              <Smart.Select name="to" label="Empfänger" customValue items={mailItems} rules={{ required: true }} />
              <Button $primary loading={pending} className="w-full">
                Abo auf Entwurf setzen & Nachricht senden
              </Button>
              <div className="flex gap-3">
                <Button
                  className="w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    setBackToCustomer(null);
                  }}
                >
                  Abbrechen
                </Button>
                <Button
                  loading={pending}
                  className="w-full"
                  onClick={(e) => {
                    e.preventDefault();
                    withFeedback(
                      async () => {
                        await post(`admin/membership-templates/${selectedVersion?.id}/state`, {
                          transition: 'Entwurf',
                        });
                        await mutate();
                        setBackToCustomer(null);
                        setSearch((prev) => {
                          prev.set('tab', 1);
                          return prev;
                        });
                      },
                      {
                        success: 'Status wurde geändert',
                        error: 'Status konnte nicht geändert werden',
                      },
                    );
                  }}
                >
                  Ohne Nachricht auf Entwurf setzen
                </Button>
              </div>
            </Smart.Form>
          </div>
        </Modal>

        <Section.Container className="flex justify-between items-center">
          <Section.BackButton
            location={-1}
            // location={`../?backend=${search.get('backend') || membershipTemplate?.backendId}${
            //   search.get('status') ? '&status=' + search.get('status') : ''
            // }`}
          />
          <Section.Heading>
            {templateID && membershipTemplate ? `Bearbeite "${membershipTemplate?.name}"` : 'Neues Abo erstellen'}
          </Section.Heading>
          <div className="ml-auto flex gap-3 items-center">
            {templateID &&
              membershipTemplate &&
              (isLoadingVersionState ? (
                <>
                  <Skeleton className="w-[150px] h-9" />
                  <Skeleton className="w-[150px] h-9" />
                  <Skeleton className="w-[150px] h-9" />
                </>
              ) : (
                <>
                  {userCan?.delete && <DeleteButton selectedVersion={selectedVersion} />}
                  {userCan?.changeStatus && <PrevButton />}
                  {userCan?.changeStatus && selectedVersion.draftStatus !== null && (
                    <NextButton readyState={readyState} />
                  )}

                  {canDraftPerm?.length > 0 && selectedVersion?.draftStatus === null && <CreateDraftButton />}
                </>
              ))}
          </div>
        </Section.Container>
        <Section.Divider />
        {membershipTemplate ? (
          <>
            <Section.Container>
              <div className="flex justify-between items-center mb-6">
                {/* Duplicate Button*/}
                {/* !isVoucher && membershipTemplate.versions.length > 0 && <DuplicateButton /> */}
                {isVoucher && (
                  <Badge theme="gray">
                    {(membershipTemplate as any).type === 'addon' ? 'ADD-ON-Gutschein' : 'Abo-Gutschein'}
                  </Badge>
                )}

                {/* Version Select */}
                {membershipTemplate.versions.length > 0 && (
                  <div className="ml-auto">
                    <div className="text-sm font-medium leading-5 text-gray-500">Version </div>
                    <SimpleSelect
                      value={selectedVersion?.id}
                      onChange={(v) => {
                        console.log(v);
                        setSelectedVersion(v);
                        // setSelectedVersion(membershipTemplate.versions.find((version) => version.id === v))
                      }}
                      items={[...membershipTemplate.versions, membershipTemplate.active]
                        .filter(Boolean)
                        .sort((a: any, b: any) => (a._modified < b._modified ? 1 : -1))
                        .map((v: any) => ({
                          label: (
                            <div className="flex gap-2 items-center">
                              {v.id === membershipTemplate?.active?.id || v.id === membershipTemplate?.draft?.id ? (
                                <>
                                  <StateBadge version={v} /> {dayjs(v._modified).format('DD.MM.YYYY')}
                                </>
                              ) : (
                                <>
                                  <Badge theme="gray">Archiv</Badge> {dayjs(v._modified).format('DD.MM.YYYY')}{' '}
                                </>
                              )}
                            </div>
                          ) as any,
                          value: v.id,
                        }))}
                    />
                  </div>
                )}
              </div>

              <ConfirmDelete
                title="Ungespeicherte Änderungen"
                description="Du hast noch nicht gespeicherte Änderungen. Möchtest du diese verwerfen?"
                deleteLabel="Trotzdem weiter"
                onClose={() => setConfirmChange(null)}
                onDelete={() => {
                  setCanChange(true);
                  setActiveTab(confirmChange);
                  setConfirmChange(null);
                }}
                open={confirmChange !== null}
              />
            </Section.Container>
            <Section.Container>
              {/* Tabs */}

              <Tab className="bg-none! shadow-none">
                <Section.Sticky>
                  <Tab.Head className=" bg-white dark:bg-gray-700 z-50">
                    {templateID && (
                      <Tab.Header
                        title={'Übersicht'}
                        active={activeTab === Tabs.Overview}
                        onClick={() => changeTab(Tabs.Overview)}
                      />
                    )}

                    {isVoucher && (
                      <>
                        {/* Tabs nur bei Addon oder Gutscheinkonfig */}
                        <Tab.Header
                          title={'Einstellungen'}
                          active={activeTab === Tabs.Selling}
                          onClick={() => changeTab(Tabs.Selling)}
                        />
                        <Tab.Header
                          title={'Gutschein-Codes'}
                          active={activeTab === Tabs.Codes}
                          onClick={() => changeTab(Tabs.Codes)}
                        />
                      </>
                    )}

                    {/* remove stage check if ready for prod */}
                    {templateID && import.meta.env.VITE_env === 'stage' && (
                      <Tab.Header
                        title="Zustimmungen"
                        active={activeTab === Tabs.Consent}
                        onClick={() => changeTab(Tabs.Consent)}
                      />
                    )}

                    {membershipTemplate.type !== 'addon' &&
                      (isLoadingVersionState ? (
                        <Skeleton className="w-[150px] mx-2 mt-5" />
                      ) : (
                        userCan?.seePrices && (
                          <Tab.Header
                            title={
                              <>
                                {!completeState.prices && <div className="w-2 h-2 rounded-full bg-red-500" />} Preise
                                und Laufzeit
                              </>
                            }
                            active={activeTab === Tabs.Prices}
                            onClick={() => changeTab(Tabs.Prices)}
                          />
                        )
                      ))}
                    {templateID &&
                      (isLoadingVersionState ? (
                        <Skeleton className="w-[150px] mx-2 mt-5" />
                      ) : (
                        userCan?.seeTemplate && (
                          <Tab.Header
                            title={
                              <>
                                {!completeState.templates && <div className="w-2 h-2 rounded-full bg-red-500" />}{' '}
                                Vertragsvorlage
                              </>
                            }
                            active={activeTab === Tabs.Template}
                            onClick={() => changeTab(Tabs.Template)}
                          />
                        )
                      ))}
                    {membershipTemplate.type !== 'addon' &&
                      templateID &&
                      (isLoadingVersionState ? (
                        <Skeleton className="w-[150px] mx-2 mt-5" />
                      ) : (
                        userCan?.seeHector && (
                          <Tab.Header
                            title={
                              <>
                                {!completeState.hector && <div className="w-2 h-2 rounded-full bg-red-500" />}{' '}
                                Hector-Einstellungen
                              </>
                            }
                            active={activeTab === Tabs.Hector}
                            onClick={() => changeTab(Tabs.Hector)}
                          />
                        )
                      ))}
                    {!window.location.href.includes('create') &&
                      (['Entwurf', 'In Vorbereitung', 'Im Test', 'Wartet auf Freigabe'].includes(
                        selectedVersion?.draftStatus,
                      ) ||
                        selectedVersion?.draftStatus === null) && (
                        <Tab.Header
                          title={
                            <>
                              {!canNext && <div className="w-2 h-2 rounded-full bg-red-500" />}
                              {selectedVersion?.draftStatus === 'Wartet auf Freigabe' ? 'Freigabe' : 'Test'}
                            </>
                          }
                          active={activeTab === Tabs.Test}
                          onClick={() => changeTab(Tabs.Test)}
                        />
                      )}
                    {templateID &&
                      (isLoadingVersionState ? (
                        <Skeleton className="w-[150px] mt-5 mx-2" />
                      ) : (
                        userCan?.seeComments && (
                          <Tab.Header
                            title={`Kommentare (${selectedVersion?.comments?.length || 0})`}
                            active={activeTab === Tabs.Comments}
                            onClick={() => changeTab(Tabs.Comments)}
                          />
                        )
                      ))}
                  </Tab.Head>
                </Section.Sticky>

                <Tab.Content>
                  <TabsContext.Provider value={{ canChange, setCanChange }}>
                    {isLoading ? (
                      <div>
                        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
                          <Skeleton className="w-10" />
                          <Skeleton className="w-full h-12" />
                        </div>
                        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
                          <Skeleton className="w-10" />
                          <Skeleton className="w-full h-12" />
                        </div>
                        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
                          <Skeleton className="w-10" />
                          <Skeleton className="w-full h-12" />
                        </div>
                        <div className={'grid grid-cols-[1fr_2fr] px-2 border-b gap-6 pb-6 my-6'}>
                          <Skeleton className="w-10" />
                          <Skeleton className="w-full h-12" />
                        </div>
                      </div>
                    ) : (
                      <>
                        {!isLoadingVersionState &&
                          !userCan.edit &&
                          activeTab !== Tabs.Test &&
                          activeTab !== Tabs.Comments && <Info>Bearbeitung gesperrt</Info>}
                        {![Tabs.Codes, Tabs.Selling].includes(activeTab) && selectedVersion?.draftStatus === null && (
                          <div className="mb-3">
                            <Alert>Abos in diesem Status können nicht direkt bearbeitet werden. </Alert>
                          </div>
                        )}

                        {activeTab === Tabs.Test && (
                          <div className="flex flex-col relative gap-3">
                            <>
                              <ReleaseForm
                                Next={() => userCan?.changeStatus && <NextButton readyState={readyState} />}
                                Prev={PrevButton}
                              />
                            </>
                          </div>
                        )}
                        {activeTab === Tabs.Overview && (
                          <>
                            {!allComplete && selectedVersion?.draftStatus === 'In Vorbereitung' && userCan?.edit && (
                              <Alert>
                                Los geht's mit der Abo-Anlage! Bitte hinterlege eine Vertragsvorlage und verknüpfe das
                                Abo in den Hector-Einstellungen mit den Hector-Abos. Fordere dann bitte rechts oben den
                                internen Test an.
                              </Alert>
                            )}

                            {isVoucher && (
                              <>
                                <PreviewWrapper
                                  title="Einstellungen"
                                  status={completeState.selling}
                                  canEdit={userCan?.edit}
                                  editAction={() => setActiveTab(Tabs.Selling)}
                                >
                                  <>
                                    <div className="flex gap-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
                                      <div className="w-full">
                                        <PreviewCell label="Titel">{membershipTemplate.name}</PreviewCell>
                                        <PreviewCell label="Verkauf">
                                          {membershipTemplate.activatedForSale ? (
                                            <CheckIcon className="w-4 h-4 text-green-500" />
                                          ) : (
                                            <XMarkIcon className="w-4 h-4 text-red-500" />
                                          )}
                                        </PreviewCell>
                                        <PreviewCell label="Zeitraum">
                                          {membershipTemplate.dateStart
                                            ? dayjs(membershipTemplate.dateStart).format('DD.MM.YY')
                                            : dayjs(membershipTemplate.created).format('DD.MM.YY')}
                                          -{' '}
                                          {membershipTemplate.dateEnd
                                            ? dayjs(membershipTemplate.dateEnd).format('DD.MM.YY')
                                            : '∞'}
                                        </PreviewCell>
                                      </div>
                                      <div className="w-full">
                                        {membershipTemplate?.images?.map((image: AssetResource) => (
                                          <img src={image.thumbnails[0].url} key={image.assetID} />
                                        ))}
                                      </div>
                                      <div className="w-full flex">
                                        <PreviewCell last label="">
                                          <div className="text-gray-900 dark:text-gray-100 font-medium mb-2">
                                            Beschreibung
                                          </div>
                                          <div className="text-sm font-normal text-gray-800 dark:text-gray-200">
                                            {membershipTemplate.description}
                                          </div>
                                        </PreviewCell>
                                      </div>
                                    </div>
                                  </>
                                </PreviewWrapper>
                                {console.log(membershipTemplate)}

                                <PreviewWrapper
                                  title="Codes"
                                  status={true}
                                  canEdit={userCan?.edit}
                                  editAction={() => setActiveTab(Tabs.Codes)}
                                >
                                  <CodesTable showActions={false} />
                                </PreviewWrapper>
                              </>
                            )}

                            {membershipTemplate.type !== 'addon' && (
                              <PreviewWrapper
                                title="Preise und Laufzeit"
                                status={completeState.prices}
                                canEdit={userCan?.edit}
                                editAction={() => setActiveTab(Tabs.Prices)}
                                canSecondary={userCan?.preview}
                                secondaryLabel="Web Vorschau"
                                secondaryAction={() => setSearch('webpreview=true')}
                              >
                                <>
                                  <PricesPreview
                                    selectedVersion={selectedVersion}
                                    membershipTemplate={membershipTemplate}
                                  />
                                  <WebPreview
                                    selectedVersion={selectedVersion}
                                    membershipTemplate={membershipTemplate}
                                    open={search.get('webpreview') === 'true'}
                                  />
                                </>
                              </PreviewWrapper>
                            )}

                            {selectedVersion?.draftStatus !== 'Entwurf' && (
                              <PreviewWrapper
                                title="Vertragsvorlage"
                                status={completeState.templates}
                                canEdit={userCan?.edit}
                                editAction={() => setActiveTab(Tabs.Template)}
                                canSecondary={userCan?.preview}
                                secondaryLabel={
                                  previewPending ? (
                                    <div className="flex items-center gap-3">
                                      <Spinner />
                                      {'  '}PDF Vorschau
                                    </div>
                                  ) : (
                                    'PDF Vorschau'
                                  )
                                }
                                secondaryAction={handlePreview}
                              >
                                <TemplatesPreview selectedVersion={selectedVersion} />
                              </PreviewWrapper>
                            )}
                            <PDFPreviewModal state={[preview, setPreview]} />

                            {!isAddon &&
                              membershipTemplate.type !== 'addon' &&
                              selectedVersion?.draftStatus !== 'Entwurf' && (
                                <PreviewWrapper
                                  title="Hector-Einstellungen"
                                  status={completeState.hector}
                                  canEdit={userCan?.edit}
                                  editAction={() => setActiveTab(Tabs.Hector)}
                                  canSecondary={userCan?.preview}
                                  secondaryLabel="Test Antrag"
                                  secondaryAction={() => {
                                    bookTestMembership();
                                    setSearch((prev) => {
                                      prev.set('bookingpreview', 'true');
                                      return prev;
                                    });
                                  }}
                                >
                                  <HectorPreview selectedVersion={selectedVersion} />
                                </PreviewWrapper>
                              )}
                            <BookingPreview
                              selectedVersion={selectedVersion}
                              feedbackDisabled={true}
                              testBookingId={testBooking}
                              open={search.get('bookingpreview') === 'true'}
                            />
                          </>
                        )}

                        {activeTab === Tabs.Prices && <PricesForm />}
                        {activeTab === Tabs.Selling && <SellingForm />}
                        {activeTab === Tabs.Codes && <CodesForm />}
                        {activeTab === Tabs.Template && <ContractForm type={type} />}
                        {activeTab === Tabs.Consent && <ConsentSelect />}
                        {activeTab === Tabs.Hector && <HectorForm />}
                        {activeTab === Tabs.Comments && (
                          <InternalComments
                            parent={selectedVersion as any}
                            filter={(comment) => {
                              if (!isDSBUser) {
                                return !comment.title?.includes('{system}');
                              }
                              return true;
                            }}
                          />
                        )}
                      </>
                    )}
                  </TabsContext.Provider>
                </Tab.Content>
              </Tab>
            </Section.Container>
          </>
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            <Spinner />
          </div>
        )}
      </Section>
    </Layout>
  );
};
export default WithOMT(OnlineMembershipTemplateDetail);

const PreviewCell = ({ label, children, last = false }) => {
  return (
    <div
      className={classNames(
        'flex justify-between pb-1 gap-2 flex-wrap text-base font-normal',
        !last && 'border-b mb-3',
      )}
    >
      <div className="text-gray-500 dark:text-gray-300 text-sm">{label}</div>
      <div className="font-semibold text-sm text-gray-700 dark:text-gray-400"> {children}</div>
    </div>
  );
};

const PreviewWrapper = ({
  children,
  title,
  status,
  canEdit,
  editAction,
  canSecondary,
  secondaryLabel,
  secondaryAction,
}: {
  children: React.ReactNode;
  title: string;
  status: boolean;
  canEdit?: boolean;
  editAction?: () => void;
  canSecondary?: boolean;
  secondaryLabel?: React.ReactNode;

  secondaryAction?: () => void;
}) => {
  const light = useMemo(() => {
    switch (status) {
      case true:
        return 'bg-green-400 text-green-800';
      case false:
        return 'bg-red-400 text-red-800';
    }
  }, [status]);

  return (
    <div className="w-full h-full">
      <div className="flex gap-2 items-center mt-6">
        <div className={`flex items-center justify-center w-3 h-3 rounded-full ${light}`} />
        <span className="text-lg font-medium">{title}</span>
        {canEdit && (
          <Button.Action className="text-blue-500" onClick={editAction}>
            <PencilIcon className="w-4 h-5" />
          </Button.Action>
        )}
        {canSecondary && (
          <Button $secondary onClick={secondaryAction}>
            {secondaryLabel}
          </Button>
        )}
      </div>

      <div className="mt-6 h-full">{children}</div>
    </div>
  );
};

export function price(cents) {
  if (cents === null) return '–';
  return (cents / 100).toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
  });
}

function duration(dur, genitive = true) {
  if (!dur) return '–';
  return formatPeriod(dur, genitive);
}

function formatRecurring(dur: string) {
  if (!dur) return '-';
  if (dur.toLowerCase() === 'null') return 'einmalig';
  let result = '';
  const [, count, size] = dur.split('');
  result += count === '1' ? 'pro ' : `alle ${count} `;

  result += {
    D: 'Tag',
    W: 'Woche',
    M: 'Monat',
    Y: 'Jahr',
  }[size];
  result += count === '1' ? '' : 'e';
  return result;
}

const PricesPreview = ({
  selectedVersion,
  membershipTemplate,
}: {
  selectedVersion: MembershipTemplateVersion;
  membershipTemplate: any;
}) => {
  const { backends } = useBackends();
  const { data: generalTemplates } = useGeneralMembershipTemplates();
  const { isAddon } = useContext(OMTContext);
  return (
    <>
      <div className="flex gap-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
        <div className="w-full">
          <PreviewCell label={isAddon ? 'ADD-ON' : 'Abo'}>{membershipTemplate.name}</PreviewCell>
          <PreviewCell label="Backend">{backends?.[membershipTemplate.backendId]}</PreviewCell>
          <PreviewCell label="Altersbeschränkung">
            {selectedVersion?.minimumMembershipAge && 'ab ' + selectedVersion?.minimumMembershipAge}{' '}
            {selectedVersion?.maximumMembershipAge && 'bis ' + selectedVersion?.maximumMembershipAge} Jahre
          </PreviewCell>
          <PreviewCell last label="Testphase">
            {duration(selectedVersion?.trialPeriod, false)}
          </PreviewCell>
        </div>
        <div className="w-full">
          <PreviewCell label="Grundgebühr / Intervall">
            {price(selectedVersion?.mainIntervalPrice)} / {formatRecurring(selectedVersion?.mainInterval)}
          </PreviewCell>
          <PreviewCell label="Aktionspreis / Intervall">
            {price(selectedVersion?.discountIntervalPrice)}{' '}
            {selectedVersion?.discountIntervalPrice && '/ ' + formatRecurring(selectedVersion?.discountInterval)}
          </PreviewCell>
          <PreviewCell label="Aliquote Aktionsbuchung">
            {selectedVersion?.hectorSettings.aliquote
              ? translate(selectedVersion?.hectorSettings.aliquote, {
                  "{ key: 'full', title: 'Anteiliger Monat ab Testphase + voller Aktionszeitraum' }":
                    'Anteiliger Monat ab Testphase + voller Aktionszeitraum',
                  "{ key: 'partial', title: 'Voller Aktionszeitraum (ab nächsten Monatsersten)' }":
                    'Voller Aktionszeitraum (ab nächsten Monatsersten)',
                })
              : '–'}
          </PreviewCell>
          <PreviewCell last={membershipTemplate.type === 'default'} label="Aktionszeitraum">
            {duration(selectedVersion?.discountDuration, false)}
          </PreviewCell>
          {membershipTemplate.type === 'referral' && (
            <PreviewCell last label="Weiterempfehlungs-Prämie (10€)">
              {
                {
                  afterTrial: 'nach Testphase',
                  onMembershipSubmit: 'bei Abschluss',
                  none: 'keine',
                }[selectedVersion?.hectorSettings?.bonus?.value]
              }
            </PreviewCell>
          )}
          {membershipTemplate.type === 'comeback' && (
            <PreviewCell last label="Welcome-Back-Prämie">
              {formatPrice(selectedVersion?.hectorSettings.bonus?.value)}
            </PreviewCell>
          )}
        </div>
        <div className="w-full flex">
          {selectedVersion?.mainText.map((text, i) => {
            return (
              <PreviewCell key={i} last label="">
                <div className="text-gray-900 dark:text-gray-100 font-medium mb-2">{text.title}</div>
                <div className="text-sm font-normal text-gray-800 dark:text-gray-200">{text.body}</div>
              </PreviewCell>
            );
          })}
        </div>
      </div>

      {!isAddon && (
        <>
          <div className="flex flex-col gap-6 bg-white dark:bg-gray-700 rounded-lg mt-6 p-4">
            <div className="dark:text-gray-400 text-gray-900 font-semibold text-base">Rabatte</div>
            {selectedVersion?.discounts?.length === 0 ? (
              <div className="w-full p-4 flex gap-6 border rounded-lg">Keine Rabatte konfiguriert</div>
            ) : (
              selectedVersion?.discounts?.map((discount, i) => {
                return (
                  <div key={`asdasd${i}`} className="w-full p-4 flex gap-6 border rounded-lg">
                    <div className="w-full text-right">
                      <PreviewCell last label="">
                        {discount.name}
                      </PreviewCell>
                    </div>
                    <div className="w-full">
                      <PreviewCell label="Aboart">
                        {translate(discount.discountType, {
                          youth: 'Jugend',
                          loyalty: 'Treue',
                        })}
                      </PreviewCell>
                      {discount.discountType === 'youth' ? (
                        <PreviewCell label="Alter">{discount.age}</PreviewCell>
                      ) : (
                        <>
                          <PreviewCell label="Dauer">{duration(discount.duration, false)}</PreviewCell>
                          <PreviewCell label="Altersbeschränkung">
                            {discount.minAge ? <>{discount.minAge && 'ab ' + discount.minAge} Jahre</> : '-'}
                          </PreviewCell>
                        </>
                      )}

                      <PreviewCell label="Preis">{price(discount.price)}</PreviewCell>
                      <PreviewCell last label="Template">
                        {generalTemplates?.find((t) => t.id === discount.templateId)?.name} ( {discount.templateType})
                      </PreviewCell>
                    </div>
                    <div className="w-full flex">
                      <PreviewCell last label="">
                        <div className="text-gray-900 dark:text-gray-100 font-normal">{discount.description}</div>
                      </PreviewCell>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          <div className="flex flex-col gap-6 bg-white dark:bg-gray-700 rounded-lg mt-6 p-4">
            <div className="dark:text-gray-400 text-gray-900 font-semibold text-base">Gebühren</div>
            {selectedVersion?.fees.length === 0 ? (
              <div className="w-full p-4 flex gap-6 border rounded-lg">Keine Gebühren konfiguriert</div>
            ) : (
              selectedVersion?.fees.map((fee, i) => {
                return (
                  <div key={`asdasd${i}`} className="w-full p-4 flex gap-6 border rounded-lg">
                    <div className="w-full text-right">
                      <PreviewCell last label="">
                        {fee.name}
                      </PreviewCell>
                    </div>
                    <div className="w-full">
                      <PreviewCell label="Preis">{price(fee.price)}</PreviewCell>
                      <PreviewCell label="1. Abbuchung">{fee.due}</PreviewCell>
                      <PreviewCell last label="Intervall">
                        {formatRecurring(fee.interval)}
                      </PreviewCell>
                    </div>
                    <div className="w-full flex">
                      <PreviewCell last label="">
                        <div className="text-gray-900 dark:text-gray-100 font-medium mb-2">{fee.title}</div>
                        <div className="text-sm font-normal text-gray-800 dark:text-gray-200">{fee.body}</div>
                      </PreviewCell>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          <div className="flex flex-col gap-6 bg-white dark:bg-gray-700 p-4 rounded-lg mt-6">
            <div className="dark:text-gray-400 text-gray-900 font-semibold text-base">ADD-ONs</div>
            {selectedVersion?.addons.length === 0 ? (
              <div className="w-full p-4 flex gap-6 border rounded-lg">Keine Addons konfiguriert</div>
            ) : (
              selectedVersion?.addons.map((addon, i) => {
                return (
                  <div key={i} className="w-full flex gap-6 border rounded-lg p-4">
                    <div className="w-full text-right">
                      <PreviewCell last label="">
                        {addon.name}
                      </PreviewCell>
                    </div>
                    <div className="w-full">
                      <PreviewCell label="Testphase">{duration(addon.trialPeriod)}</PreviewCell>
                      <PreviewCell label="Preis">{price(addon.price)}</PreviewCell>
                      <PreviewCell label="Intervall">{formatRecurring(addon.interval)}</PreviewCell>
                      <PreviewCell label="Anzeige Preis">{price(addon.displayPrice)}</PreviewCell>
                      <PreviewCell last label="Anzeige Intervall">
                        {formatRecurring(addon?.displayInterval)}
                      </PreviewCell>
                    </div>
                    <div className="w-full flex">
                      <PreviewCell last label="">
                        <div className="text-gray-900 dark:text-gray-100 font-medium mb-2">{addon.description}</div>
                      </PreviewCell>
                    </div>
                  </div>
                );
              })
            )}
          </div>

          <div className="flex gap-3">
            <div className="flex flex-col w-full gap-6 bg-white dark:bg-gray-700 rounded-lg mt-6 p-4">
              <div className="dark:text-gray-400 text-gray-900 font-semibold text-base">Vorteile</div>
              {selectedVersion?.benefits?.length === 0 ? (
                <div className="w-full p-4 flex gap-6 border rounded-lg">Keine Vorteile konfiguriert</div>
              ) : (
                selectedVersion?.benefits?.map((benefit, i) => {
                  return (
                    <div key={i} className="w-full p-2 px-4 flex gap-6 border rounded-lg">
                      <div className="w-full text-left">
                        <div className="font-semibold">{benefit.title}</div>
                        <div className="text-sm "> {benefit.description}</div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="flex flex-col gap-6 bg-white dark:bg-gray-700 p-4 rounded-lg mt-6">
            <div className="dark:text-gray-400 text-gray-900 font-semibold text-base">Konsumentenschutz</div>
            <div className="w-full flex gap-6 border rounded-lg p-4 ">
              {selectedVersion && getHumanReadableAboDefinition(selectedVersion)}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const PreviewItem = ({ template }: any) => {
  const { data: previewURL, isLoading } = useGlobalContractPreviewImage(template.name);

  return (
    <li className="flex justify-between items-end py-3 pl-3 pr-4 text-sm">
      <div className="flex flex-col gap-4">
        <div className="max-w-[100px] border">
          {template.type === 'pdf' ? (
            <Preview small template={template} />
          ) : !isLoading ? (
            previewURL ? (
              <img src={previewURL} alt="Global Contract Preview" />
            ) : (
              <div className="text-red-600 h-20 flex items-center justify-center text-xs m-6">
                Vorschau nicht verfügbar
              </div>
            )
          ) : (
            <Spinner className="w-16 h-16 text-white mx-auto mt-6 mb-6" />
          )}
        </div>
        <div className="flex w- flex-1 items-center">
          <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
          <span className="ml-2 w- flex-1 truncate">
            {template?.name?.split('$')[1]?.replace('-', ' ') || 'Zentrale Vertragsvorlage'}
          </span>
        </div>
      </div>
    </li>
  );
};

const TemplatesPreview = ({ selectedVersion }: { selectedVersion: MembershipTemplateVersion }) => {
  return (
    <div className="flex flex-col gap-3 bg-white dark:bg-gray-700 rounded-lg p-4">
      <div className="py-4 pb-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 w-full">
        <dt className="text-sm font-medium text-gray-500">Hauptvorlage</dt>
        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-400 sm:col-span-2 sm:mt-0 ">
          <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
            {selectedVersion?.contractTemplates.length > 0 ? (
              selectedVersion?.contractTemplates.map((template) => (
                <PreviewItem key={template.id} template={template} />
              ))
            ) : (
              <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <MinusCircleIcon className="h-5 w-5 flex-shrink-0 text-red-400" aria-hidden="true" />
                  <span className="ml-2 w-0 flex-1 truncate">Keine Vorlage ausgewählt</span>
                </div>
              </li>
            )}
          </ul>
        </dd>
      </div>

      <div className="py-4 pb-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 w-full">
        <dt className="text-sm font-medium text-gray-500">Zustimmungen</dt>
        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-400 sm:col-span-2 sm:mt-0">
          <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
            {selectedVersion?.consents.map((consent) => (
              <li key={consent.id} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-2 w-0 flex-1 truncate">{consent?.name}</span>
                </div>
              </li>
            ))}
          </ul>
        </dd>
      </div>

      <div className="py-4 pb-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 w-full">
        <dt className="text-sm font-medium text-gray-500">Rechtstexte</dt>
        <dd className="mt-1 text-sm text-gray-900 dark:text-gray-400 sm:col-span-2 sm:mt-0">
          <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
            {selectedVersion?.legalTemplates.map((template) => (
              <li key={template.id} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                <div className="flex w-0 flex-1 items-center">
                  <DocumentIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span className="ml-2 w-0 flex-1 truncate">{template?.name?.split('-')?.[1]}</span>
                </div>
              </li>
            ))}
          </ul>
        </dd>
      </div>
    </div>
  );
};

const HectorPreview = ({ selectedVersion }: { selectedVersion: MembershipTemplateVersion }) => {
  const { data: generalTemplates } = useGeneralMembershipTemplates();
  const versionNames = useCallback(
    (versionAbos) => {
      if (!(versionAbos && generalTemplates)) return [];
      return versionAbos.map((v) =>
        generalTemplates.find(({ id, type }) => id === v.templateId && type === v.templateType),
      );
    },
    [generalTemplates],
  );
  return (
    <>
      <div className="flex flex-col gap-6 bg-white dark:bg-gray-700 p-4 rounded-lg">
        <PreviewCell label="Hauptabo">
          <div className="flex items-end flex-col gap-3 pb-3">
            {versionNames(selectedVersion?.mainAbo).length > 0
              ? versionNames(selectedVersion?.mainAbo).map((v, i) => (
                  <Badge key={i} className="text-xs">
                    {v?.name}
                  </Badge>
                ))
              : 'keine'}
          </div>
        </PreviewCell>
        <PreviewCell label="im Anschluss zu buchende Hector-Abovorlage">
          <div className="flex items-end flex-col gap-3 pb-3">
            {versionNames(selectedVersion?.abosAtEnd).length > 0
              ? versionNames(selectedVersion?.abosAtEnd).map((v, i) => (
                  <Badge key={i} className="text-xs">
                    {v?.name}
                  </Badge>
                ))
              : 'keine'}
          </div>
        </PreviewCell>

        <PreviewCell label="parallel gebuchte Abos">
          <div className="flex items-end flex-col gap-3 pb-3">
            {versionNames(selectedVersion?.abosParallel).length > 0
              ? versionNames(selectedVersion?.abosParallel).map((v, i) => (
                  <Badge key={i} className="text-xs">
                    {v?.name}
                  </Badge>
                ))
              : 'keine'}
          </div>
        </PreviewCell>
        <PreviewCell last label="Nach Testphase zu buchende Nebenabos">
          <div className="flex items-end flex-col gap-3 pb-3">
            {versionNames(selectedVersion?.abosAfterTrial).length > 0
              ? versionNames(selectedVersion?.abosAfterTrial).map((v, i) => (
                  <Badge key={i} className="text-xs">
                    {v?.name}
                  </Badge>
                ))
              : 'keine'}
          </div>
        </PreviewCell>
      </div>
    </>
  );
};

const StateBadge = ({ version }: { version: MembershipTemplateVersion }) => {
  const { membershipTemplate, isDSBUser } = useContext(OMTContext);

  switch (version?.draftStatus) {
    case 'Entwurf':
      return (
        <Badge showDot theme="gray">
          Entwurf
        </Badge>
      );
    case 'In Vorbereitung':
      return (
        <Badge showDot theme="blue">
          In Vorbereitung
        </Badge>
      );
    case 'Im Test':
      if (!isDSBUser) {
        return (
          <Badge showDot theme="blue">
            In Vorbereitung
          </Badge>
        );
      }
      return (
        <Badge showDot theme="yellow">
          Im Test
        </Badge>
      );
    case 'Wartet auf Freigabe':
      return (
        <Badge showDot theme="yellow">
          Wartet auf Freigabe
        </Badge>
      );
    case null:
      if (version.id === membershipTemplate?.active?.id) {
        return (
          <Badge showDot theme="green">
            Live
          </Badge>
        );
      } else {
        return (
          <Badge showDot theme="gray">
            Archiv
          </Badge>
        );
      }

    default:
      return null;
  }
};

export const DuplicateSidebar = ({
  membershipTemplate,
  open,
  onClose,
}: {
  membershipTemplate: MembershipTemplate;
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm({
    defaultValues: { ...membershipTemplate },
  });

  const { api } = useSdk();
  let isAbo = location.pathname.includes('onlineMembershipTemplates');
  let isVoucher = location.pathname.includes('vouchers');
  let isAddon = location.pathname.includes('addon-templates');

  const [step, setStep] = useState<{ type: 'default' | 'comeback' | 'referral'; count: number }>({
    type: !isAbo ? 'default' : undefined,
    count: !isAbo ? 2 : 1,
  });
  let { templateID, voucherID, addonID } = useParams();

  useEffect(() => {
    if (step.type) {
      form.setValue(
        'name',
        `${membershipTemplate.name} - ${
          {
            referral: 'Freunde',
            comeback: 'Comeback',
            default: 'Kopie',
          }[step.type]
        }`,
      );
      form.setValue('type', step.type);
      const active = form.watch('active');

      const openReadyState = {
        web: {
          preis: [],
          result: 'open',
          overview: [],
          vorteile: [],
        },
        legal: {
          AGB: [],
          SEPA: [],
          result: 'open',
          PRIVACY: [],
        },
        booking: {
          abo: [],
          konto: [],
          result: 'open',
        },
      };

      form.setValue('draft.hectorSettings.readyState', {
        dsb: openReadyState,
        zmv: openReadyState,
        customer: openReadyState,
      });

      if (active) {
        form.setValue('draft', active);
        form.setValue('draft.draftStatus', 'Entwurf');
        form.setValue('active', null);
        form.setValue('versions', [active]);
      }

      if (step.type === 'referral') {
        form.setValue('draft.trialPeriod', 'P4W');
        // form.setValue('draft.voucherTemplates', []);
        // form.setValue('draft.mainAbo', null);
        // form.setValue('draft.abosParallel', []);
        // form.setValue('draft.abosAfterTrial', []);
        // form.setValue('draft.abosAtEnd', []);
        // form.setValue('draft.hectorSettings', {
        //   checkinNote: '',
        //   memberStatus: '',
        //   stopCheckinU18: false,
        //   supressRecruiterOnAdditionalTemplates: false,
        // });
        // form.setValue('draft.contractTemplates', []);
        // form.setValue('draft.voucherTemplates', []);
        form.setValue('draft.hectorSettings.bonus', { type: 'referral', value: 'afterTrial' });
        const addons = form.watch('draft.addons');
        for (const addon of addons) {
          addon.trialPeriod = 'P4W';
        }
        form.setValue('draft.addons', addons);
      }

      if (step.type === 'comeback') {
        form.setValue('draft.trialPeriod', null);
        // form.setValue('draft.hectorSettings', {
        //   checkinNote: '',
        //   memberStatus: '',
        //   stopCheckinU18: false,
        //   supressRecruiterOnAdditionalTemplates: false,
        // });
        // defaultVorteile();
        form.setValue('draft.contractTemplates', []);
        form.setValue('draft.voucherTemplates', []);
        form.setValue('draft.hectorSettings.bonus', { type: 'comeback', value: '' });
        form.setValue('draft.addons', []);
        // form.setValue('draft.mainAbo', null);
        // form.setValue('draft.abosParallel', []);
        // form.setValue('draft.abosAfterTrial', []);
        // form.setValue('draft.abosAtEnd', []);
      }

      form.setValue('draft.comments', []);
    }
  }, [step]);
  console.log(form.watch());

  const { withFeedback, pending } = useFeedback();

  const navigate = useNavigate();
  const { post } = useDSB();
  function duplicateAbo(values) {
    withFeedback(
      async () => {
        const createdNote = await api.createEntry('comment', {
          text: '{system} Neuer Entwurf wurde aus bestehender Version erstellt',
          metadata: {
            fromVersion: membershipTemplate?.id,
          },
        });

        values.draft.comments = [createdNote.id];
        delete values.draft.hectorSettings.readyState;
        let addonValues = {};

        if (isAddon) {
          addonValues = {
            salesDescription: values.salesDescription,
            profileActive: values.profileActive,
            upsellingActive: values.upsellingActive,
            terminalActive: values.terminalActive,
          };
        }

        const res = await post('admin/membership-templates', {
          name: values.name,
          backendId: values.backendId,
          type: values.type,
          mode: isAddon ? 'addon' : 'abo',
          version: values.draft,
          ...addonValues,
        });

        navigate(`${res.id}`);
        setStep({ type: undefined, count: 1 });
        onClose();
      },
      {
        success: 'Abo erfolgreich dupliziert',
        error: 'Fehler beim duplizieren',
      },
    );
  }

  return (
    <Sidebar
      open={open}
      onClose={() => {
        setStep(() => ({ type: undefined, count: 1 }));
        onClose();
      }}
    >
      <Sidebar.Head>
        <Sidebar.Heading>
          {isAbo && 'Abo'}
          {isVoucher && 'Gutschein'}
          {isAddon && 'ADD-ON'} "{membershipTemplate.name}" duplizieren
        </Sidebar.Heading>
        <Sidebar.X />
      </Sidebar.Head>
      <Sidebar.Body>
        <>
          {step.count === 1 ? (
            <>
              <div className="flex flex-col gap-2 max-w-lg m-auto mt-6">
                {isAbo && (
                  <>
                    <Button
                      $primary
                      onClick={(e) => {
                        e.stopPropagation();
                        setStep({ type: 'referral', count: 2 });
                      }}
                    >
                      Als Freunde-Abo duplizieren
                    </Button>
                    <Button
                      $primary
                      onClick={(e) => {
                        e.stopPropagation();
                        setStep({ type: 'comeback', count: 2 });
                      }}
                    >
                      Als Comeback-Abo duplizieren
                    </Button>
                  </>
                )}
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setStep({ type: 'default', count: 2 });
                  }}
                >
                  Als reguläres Abo duplizieren
                </Button>
              </div>
            </>
          ) : (
            <Form>
              <div className="text-gray-500 mb-6">
                Die Werte des aktuellen Live Abos werden übernommen außer Testphase und ADD-ONs, folgende Einstellungen
                können angepasst werden :
              </div>
              <Form.Item $first>
                <Form.Item.Label>Name</Form.Item.Label>
                <Form.Item.Body>
                  <Input type="text" {...form.register('name')} />
                </Form.Item.Body>
              </Form.Item>

              {step.type === 'referral' && (
                <>
                  <Form.Item>
                    <Form.Item.Label>Testphase</Form.Item.Label>
                    <Form.Item.Body>
                      <Input.TimeInterval {...form.register('draft.trialPeriod')} />
                    </Form.Item.Body>
                  </Form.Item>
                  <Form.Item>
                    <Form.Item.Label>Weiterempfehlungs-Prämie (10€)</Form.Item.Label>
                    <Form.Item.Body>
                      <SelectInput
                        control={form.control}
                        name="draft.hectorSettings.bonus"
                        items={[
                          { label: 'nach Testphase', value: { type: 'referral', value: 'afterTrial' } },
                          { label: 'bei Abschluss', value: { type: 'referral', value: 'onMembershipSubmit' } },
                          { label: 'keine', value: { type: 'referral', value: 'none' } },
                        ]}
                      />
                    </Form.Item.Body>
                  </Form.Item>
                  <div className="font-medium mt-6">ADD-ONs</div>

                  {form.watch('draft.addons')?.length > 0 ? (
                    form.watch('draft.addons')?.map((addon, i) => (
                      <>
                        <Form.Item>
                          <Form.Item.Label>ADD-ON {addon.name} Testphase</Form.Item.Label>
                          <Form.Item.Body>
                            <Input.TimeInterval {...form.register(`draft.addons.${i}.trialPeriod`)} />
                          </Form.Item.Body>
                        </Form.Item>
                      </>
                    ))
                  ) : (
                    <p className="text-xs text-gray-500 ">
                      Keine ADD-ONs angelegt. Du kannst später weitere ADD-ONs hinzufügen
                    </p>
                  )}
                </>
              )}

              {step.type === 'comeback' && (
                <>
                  <div className="col-span-2">
                    <Form.Item>
                      <Form.Item.Label>Welcome-Back-Prämie</Form.Item.Label>
                      <Form.Item.Body>
                        <Input.PricePerInterval
                          price={form.register('draft.hectorSettings.bonus.value', {
                            valueAsNumber: true,
                            required: true,
                          })}
                          overrideOptions={[{ label: 'einmalig', value: 'NULL' }]}
                          interval={() => {}}
                        />
                      </Form.Item.Body>
                    </Form.Item>
                    <Form.Item.SortableList form={form} name="draft.fees" appendLabel="Gebühr hinzufügen">
                      {(i) => (
                        <Form.Item $first $dense>
                          <Form.Item.Label>Gebühren</Form.Item.Label>
                          <Form.Item $first $dense>
                            <Form.Item.Label>Name</Form.Item.Label>
                            <Input type="text" {...form.register(`draft.fees.${i}.name`, { required: true })} />

                            {/*@ts-ignore*/}
                            {form.formState.errors.draft?.fees?.[i]?.name && (
                              <small className="text-red-500">Pflichtfeld</small>
                            )}
                          </Form.Item>

                          <div className="flex gap-3">
                            <Form.Item $first $dense>
                              <Form.Item.Label>Preis / Intervall</Form.Item.Label>
                              <Input.PricePerInterval
                                price={form.register(`draft.fees.${i}.price`, {
                                  valueAsNumber: true,
                                  required: true,
                                })}
                                interval={form.register(`draft.fees.${i}.interval`, {
                                  setValueAs: (v) => (v === 'NULL' ? null : v),
                                  // required: true,
                                })}
                                overrideOptions={hectorIntervals}
                              />
                              {/*@ts-ignore*/}
                              {(form.formState.errors.draft?.fees?.[i]?.interval ||
                                /*@ts-ignore*/
                                form.formState.errors.draft?.fees?.[i]?.price) && (
                                <small className="text-red-500">Pflichtfeld</small>
                              )}
                            </Form.Item>
                            <Form.Item $first $dense>
                              <Form.Item.Label>Erste Abbuchung</Form.Item.Label>
                              <SelectInput
                                control={form.control}
                                name={`draft.fees.${i}.due`}
                                rules={{ required: true }}
                                items={[
                                  { label: 'nach Testphase', value: 'nach Testphase' },
                                  { label: 'nach 3 Monaten', value: 'nach 3 Monaten' },
                                  { label: 'nach 6 Monaten', value: 'nach 6 Monaten' },
                                  { label: 'nach 12 Monaten', value: 'nach 12 Monaten' },
                                  { label: 'bei Abschluss (nicht empfohlen)', value: 'bei Abschluss' },
                                ]}
                              />
                              {/* <Input */}
                              {/*   type="text" */}
                              {/*   {...form.register(`draft.fees.${i}.due`, { required: true })} */}
                              {/* /> */}
                              {/*@ts-ignore*/}
                              {form.formState.errors.draft?.fees?.[i]?.due && (
                                <small className="text-red-500">Pflichtfeld</small>
                              )}
                            </Form.Item>
                          </div>
                          <Form.Item $first $dense>
                            <Form.Item.Label>Titel</Form.Item.Label>
                            <Input type="text" {...form.register(`draft.fees.${i}.title`, { required: true })} />

                            {/*@ts-ignore*/}
                            {form.formState.errors.draft?.fees?.[i]?.title && (
                              <small className="text-red-500">Pflichtfeld</small>
                            )}
                          </Form.Item>
                          <Form.Item $first $dense>
                            <Form.Item.Label>Beschreibung</Form.Item.Label>
                            <Input.Textarea {...form.register(`draft.fees.${i}.body`, { required: true })} />
                            {/*@ts-ignore*/}
                            {form.formState.errors.draft?.fees?.[i]?.body && (
                              <small className="text-red-500">Pflichtfeld</small>
                            )}
                          </Form.Item>
                        </Form.Item>
                      )}
                    </Form.Item.SortableList>
                    <Form.Item>
                      <Form.Item.Label>Testphase</Form.Item.Label>
                      <Form.Item.Body>Keine</Form.Item.Body>
                    </Form.Item>

                    <Form.Item>
                      <Form.Item.Label>ADD-ONs</Form.Item.Label>
                      <Form.Item.Body>Keine</Form.Item.Body>
                    </Form.Item>
                  </div>
                </>
              )}

              <div className="flex gap-3 justify-end mt-6 ">
                <Button $secondary onClick={() => setStep({ type: undefined, count: 1 })}>
                  Abbrechen
                </Button>
                <Button $primary loading={pending} onClick={form.handleSubmit(duplicateAbo)}>
                  Duplizieren
                </Button>
              </div>
            </Form>
          )}
        </>
      </Sidebar.Body>
    </Sidebar>
  );
};
