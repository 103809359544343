import { OidcSecure, useOidc } from '@axa-fr/react-oidc';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Navigate, Outlet, createBrowserRouter, redirect, useLocation, useParams } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import { ErrorBoundary } from './components/ErrorBoundary';
import LayoutNew from './components/LayoutNew';
import LocalLoader from './components/LocalLoader';
import { TabLayout, TabLink } from './components/NavigationTabs';
import ReleaseForm from './components/OnlineMembershipTemplates/ReleaseForm';
import Spinner from './components/Spinner';
import { useHasFeature } from './hooks/useFeatureFlags';
import useUser from './hooks/useUser';

export const Router = () => {
  return <RouterProvider router={router} />;
};

const FeatureProtect = ({ featureName, redirectPath }) => {
  const { hasFeature, isValidating } = useHasFeature();
  const featureValid = hasFeature(featureName);

  if (featureValid) {
    return <Outlet />;
  }

  if (isValidating === true) {
    return <Spinner />;
  }
  if (!featureValid && !isValidating) {
    console.warn(`Feature ${featureName} is not enabled. Redirecting...`);
    return <Navigate to={redirectPath} />;
  }
  return null;
};

const PermissionCheck = ({ permission, redirectPath }) => {
  const user = useUser();
  const { shortID } = useParams();

  if (user[permission]) {
    return <Outlet />;
  } else {
    console.log(`User cannnot ${permission}`, user[permission], redirectPath);
    return <Navigate to={`/${shortID ? shortID + '/' : ''}${redirectPath}`} />;
  }
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  [
    {
      path: '/',
      element: (
        <OidcSecure>
          <LayoutNew />
        </OidcSecure>
      ),
      hydrateFallbackElement: <LocalLoader />,
      errorElement: <ErrorBoundary />,
      children: [
        {
          index: true,
          lazy: async () => {
            const comp = await import('./routes/appsites/Appsites');
            return { Component: comp.default };
          },
        },
        {
          path: '/auth',
          children: [
            {
              path: 'password-reset',
              lazy: async () => {
                const comp = await import('./components/PasswordReset');
                return { Component: comp.default };
              },
              children: [
                {
                  path: ':jwt',
                  lazy: async () => {
                    const comp = await import('./components/PasswordReset');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'abort',
                  children: [
                    {
                      path: ':jwt',
                      lazy: async () => {
                        const comp = await import('./components/PasswordReset');
                        return { Component: () => comp.default({ abort: true }) };
                      },
                    },
                  ],
                },
              ],
            },
            // {
            //   path: 'email-verification',
            //   children: [
            //     {
            //       index: true,
            //       lazy: async () => {
            //         const comp = await import('./components/EmailVerification');
            //         return { Component: comp.default };
            //       },
            //     },
            //     {
            //       path: ':jwt',
            //       lazy: async () => {
            //         const comp = await import('./components/EmailVerification');
            //         return { Component: comp.default };
            //       },
            //     },
            //     {
            //       path: 'abort',
            //       children: [
            //         {
            //           path: ':jwt',
            //           lazy: async () => {
            //             const comp = await import('./components/EmailVerification');
            //             return { Component: () => comp.default({ abort: true }) };
            //           },
            //         },
            //       ],
            //     },
            //   ],
            // },
          ],
        },

        {
          path: 'create-appsite',
          lazy: async () => {
            const comp = await import('./routes/AppsiteForm');
            return { Component: comp.default };
          },
        },
        {
          path: 'announcements',
          children: [
            { index: true, lazy: async () => import('./routes/AdminNews/List') },
            {
              path: 'edit/:id',
              lazy: async () => import('./routes/AdminNews/Edit'),
            },
          ],
        },
        {
          path: 'franchise',
          lazy: async () => {
            const comp = await import('./routes/Franchise');
            return { Component: comp.default };
          },
        },
        {
          path: 'connect/:seoTitle/*',
          lazy: async () => {
            const { Connect: Component } = await import('./routes/Connect');
            return { Component };
          },
        },
        {
          path: 'dsb-content',
          element: (
            <TabLayout>
              <TabLink to="articles">Artikel</TabLink>
              <TabLink to="stories">Stories</TabLink>
              <TabLink to="quiz">Quiz</TabLink>
              <TabLink to="authors">Autoren</TabLink>
              <TabLink to="tags">Tags</TabLink>
            </TabLayout>
          ),
          children: [
            {
              index: true,
              loader: () => redirect('articles'),
            },
            {
              path: 'articles',
              lazy: async () => {
                const comp = await import('./components/Contents/Articles/List');
                return { Component: () => comp.default({ type: 'article', label: 'Artikel', dsbMode: true }) };
              },
            },
            {
              path: 'stories',
              lazy: async () => {
                const comp = await import('./components/Contents/Articles/List');
                return { Component: () => comp.default({ type: 'story', label: 'Story', dsbMode: true }) };
              },
            },

            {
              path: 'quiz',
              lazy: async () => {
                const comp = await import('./components/Contents/Articles/List');
                return { Component: () => comp.default({ type: 'quiz', label: 'Quiz', dsbMode: true }) };
              },
            },
            {
              path: 'authors',
              lazy: async () => {
                const comp = await import('./components/Contents/Articles/Authors');
                return { Component: () => comp.default({ dsbMode: true }) };
              },
            },
            {
              path: 'tags',
              lazy: async () => {
                const comp = await import('./components/Contents/Articles/Tags');
                return { Component: () => comp.default({ dsbMode: true }) };
              },
            },
          ],
        },
        {
          path: 'dsb-content/articles/:id',
          lazy: async () => {
            const comp = await import('./components/Contents/Articles/Editor');
            return { Component: () => comp.default({ dsbMode: true }) };
          },
        },
        {
          path: 'dsb-content/stories/:id',
          lazy: async () => {
            const comp = await import('./components/Contents/Articles/Editor');
            return { Component: () => comp.default({ dsbMode: true }) };
          },
        },
        {
          path: 'dsb-content/quiz/:id',
          lazy: async () => {
            const comp = await import('./components/Contents/Articles/Editor');
            return { Component: () => comp.default({ dsbMode: true }) };
          },
        },
        {
          path: 'users',
          children: [
            {
              index: true,
              lazy: async () => {
                const comp = await import('./routes/UsersCentral');
                return { Component: comp.default };
              },
            },
            {
              path: ':userID',
              lazy: async () => {
                const comp = await import('./routes/UserEdit');
                return { Component: comp.default };
              },
            },
          ],
        },
        {
          path: 'render',
          children: [
            {
              index: true,
              lazy: async () => {
                const comp = await import('./routes/RenderStatus');
                return { Component: comp.default };
              },
            },
            {
              path: ':name',
              lazy: async () => {
                const comp = await import('./routes/RenderStatusDetail');
                return { Component: comp.default };
              },
            },
          ],
        },

        //
        //With Sidemenu
        //
        {
          path: ':shortID',
          children: [
            {
              index: true,
              loader: () => redirect('dashboard'),
            },
            {
              path: 'hector-one-migration',
              element: <FeatureProtect featureName="ho-migration" redirectPath="/" />,
              children: [
                {
                  index: true,
                  lazy: async () => {
                    const comp = await import('./routes/HoMigration');
                    return { Component: comp.default };
                  },
                },
              ],
            },
            {
              path: 'dashboard',
              lazy: async () => {
                const comp = await import('./routes/Dashboard');
                return { Component: comp.default };
              },
            },
            {
              path: 'announcements',
              children: [
                {
                  index: true,
                  lazy: async () => {
                    const comp = await import('./components/AnnouncementsList');
                    return { Component: comp.default };
                  },
                },
                {
                  path: ':newsID',
                  lazy: async () => {
                    const comp = await import('./components/AnnouncementsDetail');
                    return { Component: comp.default };
                  },
                },
              ],
            },
            {
              path: 'test',
              element: <ReleaseForm Next={'ddd'} Prev={'aa'} />,
            },

            //
            // WEB
            //
            {
              path: 'web',
              children: [
                {
                  index: true,
                  loader: () => redirect('pages'),
                },
                {
                  path: 'pages',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/WebPages');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'system-site/:pageID',
                      lazy: async () => {
                        const comp = await import('./routes/SystemPageDetail');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':pageID',
                      lazy: async () => {
                        const comp = await import('./routes/WebPageDetail');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'live/:speakingUrl',
                      lazy: async () => {
                        const comp = await import('./routes/LivePreview/LivePreview');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'settings',
                  children: [
                    {
                      index: true,
                      loader: () => redirect('style'),
                    },
                    {
                      path: 'style',
                      lazy: async () => {
                        const comp = await import('./routes/StyleSettings/WebStyles');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'nav',
                      lazy: async () => {
                        const comp = await import('./routes/settings-nav/SettingsNav');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'seo',
                      lazy: async () => {
                        const comp = await import('./routes/Seo');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'redirects',
                      lazy: async () => {
                        const comp = await import('./routes/SettingsRedirect');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'text',
                      lazy: async () => {
                        const comp = await import('./routes/SettingsText');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'releases',
                  lazy: async () => {
                    const comp = await import('./routes/WebReleases');
                    return { Component: comp.default };
                  },
                },
              ],
            },
            //
            // APP
            //
            {
              path: 'app',
              children: [
                {
                  index: true,
                  loader: () => redirect('dashboard'),
                },
                {
                  path: 'dashboard',
                  lazy: async () => {
                    const comp = await import('./routes/AppDashboard');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'club-content',
                  lazy: async () => {
                    const comp = await import('./components/AppContent/AppContent');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'templates-push',
                  lazy: async () => {
                    const comp = await import('./routes/PushNotificationsHector');
                    return { Component: comp.default };
                  },
                },
                //
                // EAFG Inhalte (Appsite)
                //

                {
                  path: 'content',
                  element: (
                    <TabLayout>
                      <TabLink to="plan" featureFlag="ho-contentStudio">
                        Planung
                      </TabLink>
                      <TabLink to="articles" featureFlag="ho-contentStudio">
                        Artikel
                      </TabLink>
                      <TabLink to="stories" featureFlag="ho-contentStudio">
                        Stories
                      </TabLink>
                      <TabLink to="quiz" featureFlag="ho-contentStudio">
                        Quiz
                      </TabLink>
                      <TabLink to="authors" featureFlag="ho-contentStudio">
                        Autoren
                      </TabLink>
                      <TabLink to="tags" featureFlag="ho-contentStudio">
                        Tags
                      </TabLink>
                      <TabLink to="deep-links">Deep-Links</TabLink>
                    </TabLayout>
                  ),
                  children: [
                    {
                      index: true,
                      loader: () => redirect('articles'),
                    },
                    {
                      path: 'plan',
                      lazy: async () => {
                        const comp = await import('./routes/ContentPlan');
                        return { Component: comp.default };
                      },
                    },

                    {
                      path: 'deep-links',
                      lazy: async () => {
                        const comp = await import('./routes/DeepLinks');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'articles',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/List');
                            return { Component: () => comp.default({ type: 'article', label: 'Artikel' }) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'stories',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/List');
                            return { Component: () => comp.default({ type: 'story', label: 'Story' }) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'quiz',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/List');
                            return { Component: () => comp.default({ type: 'quiz', label: 'Quiz' }) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'authors',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/Authors');
                            return { Component: () => comp.default({}) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'tags',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/Tags');
                            return { Component: () => comp.default({}) };
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  path: 'content/articles/:id',
                  lazy: async () => {
                    const comp = await import('./components/Contents/Articles/Editor');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'content/stories/:id',
                  lazy: async () => {
                    const comp = await import('./components/Contents/Articles/Editor');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'content/quiz/:id',
                  lazy: async () => {
                    const comp = await import('./components/Contents/Articles/Editor');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'style',
                  lazy: async () => {
                    const comp = await import('./routes/StyleSettings/AppStyles');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'text',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsText');
                    return { Component: () => comp.default({ app: true }) };
                  },
                },
                {
                  path: 'settings',
                  element: (
                    <TabLayout>
                      <TabLink to="general">Allgemein</TabLink>
                      <TabLink to="review">App Review</TabLink>
                      <TabLink to="partners">Partner-Apps</TabLink>
                      <TabLink to="deep-links">Deep Links</TabLink>
                      {/* <TabLink to="content">App Inhalte</TabLink> */}
                    </TabLayout>
                  ),
                  children: [
                    { index: true, loader: () => redirect('general') },
                    {
                      path: 'general',
                      lazy: async () => {
                        const comp = await import('./routes/SettingsApp');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'deep-links',
                      lazy: async () => {
                        const comp = await import('./routes/DeepLinks');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'review',
                      lazy: async () => {
                        const comp = await import('./routes/AppReview/AppReview');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'partners',
                      lazy: async () => {
                        const comp = await import('./routes/AppReview/AppPartners');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'content',
                      lazy: async () => {
                        const comp = await import('./components/Contents/Articles/Settings');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'push',
                  lazy: async () => {
                    const comp = await import('./routes/MarketingPush');
                    return { Component: comp.default };
                  },
                },
              ],
            },

            //
            // Content Studio
            //
            //
            {
              path: 'content-studio',
              children: [
                {
                  index: true,
                  loader: () => redirect('plan'),
                },

                {
                  path: 'event-center',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./components/EventCenter/List');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':automatizationId',
                      lazy: async () => {
                        const comp = await import('./components/EventCenter/Detail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },

                {
                  path: 'plan',
                  lazy: async () => {
                    const comp = await import('./routes/ContentPlan');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'categories',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/ContentCategories');
                        return { Component: comp.default };
                      },
                    },

                    {
                      path: ':categoryID',
                      lazy: async () => {
                        const comp = await import('./routes/ContentCategoryDetail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'campaigns',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/CommunicationTool');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':campaignId',
                      lazy: async () => import('./routes/CommunicationToolDetail'),
                    },
                  ],
                },
                {
                  path: 'email-templates',
                  lazy: async () => {
                    const comp = await import('./routes/EmailDesigner/EmailDesigner');
                    return { Component: comp.default };
                  },
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/EmailDesigner/List');
                        return { Component: () => comp.default({ mode: 'email_campaign' }) };
                      },
                    },
                    {
                      path: ':id',
                      lazy: async () => {
                        const comp = await import('./routes/EmailDesigner/Designer');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'user',
                  element: (
                    <TabLayout>
                      <TabLink to="target-groups">Zielgruppen</TabLink>
                      <TabLink to="newsletter">Abonnenten</TabLink>
                    </TabLayout>
                  ),
                  children: [
                    {
                      index: true,

                      loader: () => redirect('target-groups'),
                    },
                    {
                      path: 'target-groups',
                      lazy: async () => {
                        const comp = await import('./routes/TargetGroups');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'newsletter',
                      lazy: async () => {
                        const comp = await import('./routes/NewsletterList');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },

                {
                  path: 'content',
                  element: (
                    <TabLayout>
                      <TabLink to="articles" featureFlag="ho-contentStudio">
                        Artikel
                      </TabLink>
                      <TabLink to="stories" featureFlag="ho-contentStudio">
                        Stories
                      </TabLink>
                      <TabLink to="quiz" featureFlag="ho-contentStudio">
                        Quiz
                      </TabLink>
                      <TabLink to="authors" featureFlag="ho-contentStudio">
                        Autoren
                      </TabLink>
                      <TabLink to="tags" featureFlag="ho-contentStudio">
                        Tags
                      </TabLink>
                      <TabLink to="groups" featureFlag="ho-contentStudio">
                        Gruppen
                      </TabLink>
                    </TabLayout>
                  ),
                  children: [
                    {
                      index: true,
                      loader: () => redirect('articles'),
                    },
                    {
                      path: 'articles',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/List');
                            return { Component: () => comp.default({ type: 'article', label: 'Artikel' }) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'stories',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/List');
                            return { Component: () => comp.default({ type: 'story', label: 'Story' }) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'quiz',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/List');
                            return { Component: () => comp.default({ type: 'quiz', label: 'Quiz' }) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'authors',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/Authors');
                            return { Component: () => comp.default({}) };
                          },
                        },
                      ],
                    },
                    {
                      path: 'tags',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/Tags');
                            return {
                              Component: () =>
                                comp.default({
                                  prefix: 'topic:',
                                  filter: {
                                    tag: { search: 'topic:' },
                                  },
                                }),
                            };
                          },
                        },
                      ],
                    },
                    {
                      path: 'groups',
                      element: <FeatureProtect featureName="ho-contentStudio" redirectPath="../deep-links" />,
                      children: [
                        {
                          index: true,
                          lazy: async () => {
                            const comp = await import('./components/Contents/Articles/Tags');
                            return {
                              Component: () =>
                                comp.default({
                                  prefix: 'group:',
                                  filter: {
                                    tag: { search: 'group:' },
                                  },
                                }),
                            };
                          },
                        },
                      ],
                    },
                  ],
                },

                {
                  path: 'content/articles/:id',
                  lazy: async () => {
                    const comp = await import('./components/Contents/Articles/Editor');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'content/stories/:id',
                  lazy: async () => {
                    const comp = await import('./components/Contents/Articles/Editor');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'content/quiz/:id',
                  lazy: async () => {
                    const comp = await import('./components/Contents/Articles/Editor');
                    return { Component: comp.default };
                  },
                },
              ],
            },

            //
            // MITGLIEDSCHAFT
            //
            {
              path: 'membership',
              children: [
                { index: true, loader: () => redirect('onlineMembershipTemplates') },
                {
                  path: 'schedule',
                  lazy: async () => {
                    const comp = await import('./routes/Membership/MembershipConfig');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'contracts',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/ContractsHector');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':pageID',
                      lazy: async () => {
                        const comp = await import('./routes/TemplateDesigner/TemplateDesigner');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'locations',
                  lazy: async () => {
                    const comp = await import('./routes/Stripe/Locations');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'referral',
                  lazy: async () => {
                    const comp = await import('./routes/Referral');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'text-templates',
                  lazy: async () => {
                    const comp = await import('./components/OnlineMembershipTemplates/TextTemplates');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'onlineMembershipTemplates',
                  element: <FeatureProtect featureName="ho-onlineMembershipTemplates" redirectPath="../contracts" />,
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/OnlineMembershipTemplates');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'create',
                      lazy: async () => {
                        const comp = await import('./routes/OnlineMembershipTemplateDetail');
                        return { Component: () => comp.default({ type: 'contract' }) };
                      },
                    },
                    {
                      path: 'designer',
                      lazy: async () => {
                        const comp = await import('./routes/GlobalContract');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':templateID',
                      lazy: async () => {
                        const comp = await import('./routes/OnlineMembershipTemplateDetail');
                        return { Component: () => comp.default({ type: 'contract' }) };
                      },
                    },
                    {
                      path: ':pageID',
                      lazy: async () => {
                        const comp = await import('./routes/TemplateDesigner/TemplateDesigner');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'addons',
                  lazy: async () => {
                    const comp = await import('./routes/Addons');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'addon-templates',
                  children: [
                    {
                      index: true,

                      lazy: async () => {
                        const comp = await import('./routes/HoAddons');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'designer',
                      lazy: async () => {
                        const comp = await import('./routes/GlobalContract');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':addonID',
                      lazy: async () => {
                        const comp = await import('./routes/OnlineMembershipTemplateDetail');
                        return { Component: () => comp.default({ type: 'addon' }) };
                      },
                    },
                    {
                      path: 'create',
                      lazy: async () => {
                        const comp = await import('./routes/OnlineMembershipTemplateDetail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'discounts',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/Discounts/DiscountList');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':discountID',
                      lazy: async () => {
                        const comp = await import('./routes/Discounts/DiscountDetail');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':discountID/print',
                      lazy: async () => {
                        const comp = await import('./routes/Discounts/DiscountPrint');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'vouchers-old',
                  children: [
                    {
                      index: true,
                      //TODO hasFeature
                      lazy: async () => {
                        const comp = await import('./routes/Vouchers');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },

                {
                  path: 'vouchers',
                  children: [
                    {
                      index: true,
                      //TODO hasFeature
                      lazy: async () => {
                        const comp = await import('./routes/HoVoucher/HoVouchers');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'designer',
                      lazy: async () => {
                        const comp = await import('./routes/GlobalContract');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':voucherID',
                      lazy: async () => {
                        const comp = await import('./routes/OnlineMembershipTemplateDetail');
                        return { Component: () => comp.default({ type: 'voucher' }) };
                      },
                    },
                    {
                      path: ':voucherID/print',
                      lazy: async () => {
                        const comp = await import('./routes/Discounts/DiscountPrint');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'create',
                      lazy: async () => {
                        const comp = await import('./routes/HoVoucher/HoVoucherCreate');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'email',
                  lazy: async () => {
                    const comp = await import('./routes/EmailDesigner/EmailDesigner');
                    return { Component: comp.default };
                  },
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/EmailDesigner/List');
                        return { Component: () => comp.default({ mode: 'email' }) };
                      },
                    },
                    {
                      path: ':id',
                      lazy: async () => {
                        const comp = await import('./routes/EmailDesigner/Designer');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'default-contact-settings',
                  lazy: async () => {
                    const comp = await import('./routes/ContactSettings');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'cancel',
                  lazy: async () => {
                    const comp = await import('./routes/Cancellation');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'bistro',
                  lazy: async () => {
                    const comp = await import('./routes/Bistro');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'documents',
                  lazy: async () => {
                    const comp = await import('./routes/Documents');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'membersarea',
                  lazy: async () => {
                    const comp = await import('./routes/MembersArea');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'consents',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/UserConsent/ConsentsList');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'create',
                      lazy: async () => {
                        const comp = await import('./routes/UserConsent/ConsentsCreate');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':consentID',
                      lazy: async () => {
                        const comp = await import('./routes/UserConsent/ConsentDetail');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':consentID/upload',
                      lazy: async () => {
                        const comp = await import('./routes/UserConsent/CsvTool');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
              ],
            },
            //
            // CLUBs
            //
            {
              path: 'clubs',
              children: [
                { index: true, loader: () => redirect('settings') },
                {
                  path: 'settings',
                  lazy: async () => {
                    const comp = await import('./routes/ClubsHector');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'hecinfo',
                  element: (
                    <TabLayout noOverflow>
                      <TabLink to="edit">Slider</TabLink>
                      <TabLink to="slides">Slides</TabLink>
                      <TabLink to="monitors">Monitore</TabLink>
                    </TabLayout>
                  ),
                  children: [
                    { index: true, loader: () => redirect('edit') },
                    {
                      path: 'edit',
                      lazy: async () => {
                        const comp = await import('./routes/HecInfo/HecInfo');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'slides',
                      lazy: async () => {
                        const comp = await import('./routes/HecInfo/HecInfoSlides');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'monitors',
                      lazy: async () => {
                        const comp = await import('./routes/HecInfo/HecInfoScreens');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'features',
                  lazy: async () => {
                    const comp = await import('./routes/Features');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'courses',
                  element: (
                    <TabLayout>
                      <TabLink to="general">Kurse</TabLink>
                      <TabLink to="live">Live</TabLink>
                      <TabLink to="types">Kurstypen</TabLink>
                    </TabLayout>
                  ),
                  children: [
                    {
                      path: 'general',
                      lazy: async () => {
                        const comp = await import('./routes/Courses');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'live',
                      lazy: async () => {
                        const comp = await import('./routes/streams/Streams');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'types',
                      lazy: async () => {
                        const comp = await import('./routes/CourseTypes');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'homegym',
                  lazy: async () => {
                    const comp = await import('./routes/Homegym');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'employees',
                  lazy: async () => {
                    const comp = await import('./routes/Employees');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'appointments',
                  element: (
                    <TabLayout>
                      <TabLink to="types">Terminarten</TabLink>
                      <TabLink to="counseling">Beratungstermine</TabLink>
                      <TabLink to="click-and-meet">Click and Meet</TabLink>
                    </TabLayout>
                  ),
                  children: [
                    {
                      index: true,
                      loader: () => redirect('types'),
                    },
                    {
                      path: 'types',
                      lazy: async () => {
                        const comp = await import('./routes/AppointmentTypes');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'counseling',
                      lazy: async () => {
                        const comp = await import('./routes/Counseling');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'click-and-meet',
                      lazy: async () => {
                        const comp = await import('./routes/ClickAndMeet');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'social-media',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsSocialMedia');
                    return { Component: comp.default };
                  },
                },
              ],
            },
            //
            // Mitglieder
            //
            {
              path: 'club-members',
              element: <FeatureProtect featureName="ho-accounts" redirectPath="../" />,
              children: [
                { index: true, loader: () => redirect('membership-requests') },
                {
                  path: 'membership-requests',
                  lazy: async () => {
                    const comp = await import('./routes/MembershipRequests/MembershipRequests');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'addon-requests',
                  lazy: async () => {
                    const comp = await import('./routes/AddonRequests/AddonRequests');
                    return { Component: comp.default };
                  },
                },

                {
                  path: 'addon-cancellations',
                  lazy: async () => {
                    const comp = await import('./routes/CancelationRequestsAddon');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'cancellation-requests',
                  lazy: async () => {
                    const comp = await import('./routes/CancelationRequests');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'breaks',
                  lazy: async () => {
                    const comp = await import('./routes/CancelationRequestsBreaks');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'online-accounts',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/OnlineAccounts/OnlineAccounts');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':accountID',
                      lazy: async () => {
                        const comp = await import('./routes/OnlineAccounts/OnlineAccountsDetail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'salutations',
                  lazy: async () => {
                    const comp = await import('./routes/Salutations');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'newsletter',
                  lazy: async () => {
                    const comp = await import('./routes/NewsletterList');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'communication',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/CommunicationTool');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':campaignId',
                      lazy: async () => import('./routes/CommunicationToolDetail'),
                    },
                  ],
                },

                {
                  path: 'target-groups',
                  lazy: async () => {
                    const comp = await import('./routes/TargetGroups');
                    return { Component: comp.default };
                  },
                },
              ],
            },

            //
            // DSB MODULE
            //
            {
              path: 'dsb',
              children: [
                { index: true, loader: () => redirect('shop') },
                {
                  path: 'shop',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsShp');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'seca',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsSeca');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'covid',
                  lazy: async () => {
                    const comp = await import('./routes/CovidSettings');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'chat',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsChat');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'settings',
                  children: [
                    { index: true, loader: () => redirect('course') },
                    {
                      path: 'course',
                      lazy: async () => {
                        const comp = await import('./routes/SettingsDSBCours');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
              ],
            },
            //
            // Statistiken & Analytics
            //
            {
              path: 'stats',
              children: [
                { index: true, loader: () => redirect('overview') },
                {
                  path: 'hoStats',

                  // element: <PermissionCheck permission="canReadStats" redirectPath="stats/analytics" />,
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/Stats/HoStats');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':clubID',
                      lazy: async () => {
                        const comp = await import('./routes/Stats/HoClubStatsDetail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },

                {
                  path: 'overview',

                  element: <PermissionCheck permission="canReadStats" redirectPath="stats/analytics" />,
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/Stats/Stats');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':clubID',
                      lazy: async () => {
                        const comp = await import('./routes/Stats/ClubStatsDetail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },

                {
                  path: 'marketing-reports',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/MarketingReports');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':reportID',
                      lazy: async () => {
                        const comp = await import('./routes/MarketingReportsDetail');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'reports',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/Reports/Reports');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':reportID',
                      lazy: async () => {
                        const comp = await import('./routes/Reports/ReportDetail');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'check/:reportID',
                      lazy: async () => {
                        const comp = await import('./routes/Reports/ReportCheck');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'analytics',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsAnalytics');
                    return { Component: comp.default };
                  },
                },
                {
                  path: ':clubID',
                  lazy: async () => {
                    const comp = await import('./routes/Stats/ClubStatsDetail');
                    return { Component: comp.default };
                  },
                },
              ],
            },

            //
            // Verwaltung
            //
            {
              path: 'manage',
              children: [
                { index: true, loader: () => redirect('assets') },
                {
                  path: 'assets',
                  lazy: async () => {
                    const comp = await import('./routes/AssetManager/AssetManager');
                    return { Component: comp.default };
                  },
                },

                {
                  path: 'countries',
                  lazy: async () => {
                    const comp = await import('./routes/Countries');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'groups',
                  lazy: async () => {
                    const comp = await import('./routes/Groups');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'feature-flags',
                  lazy: async () => {
                    const comp = await import('./routes/FeatureFlags');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'hec-pay',
                  lazy: async () => {
                    const comp = await import('./routes/HecPay');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'domain',
                  lazy: async () => {
                    const comp = await import('./routes/SettingsDomain');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'users',
                  children: [
                    {
                      index: true,
                      lazy: async () => {
                        const comp = await import('./routes/UsersAppsite');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: 'invite',
                      lazy: async () => {
                        const comp = await import('./components/UserCentral/Invite');
                        return { Component: comp.default };
                      },
                    },
                    {
                      path: ':userID',
                      lazy: async () => {
                        const comp = await import('./routes/UserEdit');
                        return { Component: comp.default };
                      },
                    },
                  ],
                },
                {
                  path: 'history',
                  lazy: async () => {
                    const comp = await import('./routes/History');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'makros',
                  lazy: async () => {
                    const comp = await import('./routes/MakroConfig');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'tba',
                  lazy: async () => {
                    const comp = await import('./routes/TbaSettings');
                    return { Component: comp.default };
                  },
                },
                {
                  path: 'setup-assistant',
                  lazy: async () => {
                    const comp = await import('./routes/SetupAssistant/SetupAssistant');
                    return { Component: comp.default };
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'authentication',
      children: [
        {
          // auth would work without this route, but we need to fetch the state for redirects
          path: 'callback',
          element: <Callback />,
        },
      ],
    },
    {
      path: 'signup',
      element: <SignupCallback />,
    },
  ],
  {
    v7_relativeSplatPath: true,
    v7_startTransition: true,
    v7_fetcherPersist: true,

    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
    v7_normalizeFormMethod: true,
  },
);

function Callback() {
  const loc = useLocation();
  const state = new URLSearchParams(loc.search).get('state');
  if (state && state.startsWith('redirect:')) {
    // made up url format for redirects..
    // state is used also for other things so we have to prepend a special string..
    const [_, route] = state.split('redirect:');
    return <Navigate to={route} />;
  }
  return null;
}

function SignupCallback() {
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const invite = query.get('invite');
  const email = query.get('email');
  const { login } = useOidc();
  useEffect(() => {
    login(`/`, {
      extras: query.toString(),
      //prompt: 'create',
    });
  }, [invite, email]);
  return <p>Du wirst weitergeleitet...</p>;
}
